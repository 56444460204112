import ResponderListsController from './responder_lists_controller';
import $ from 'jquery';
import Select2 from 'select2';
import 'select2/dist/css/select2';

export default class extends ResponderListsController {
  static targets = ['list', 'selectContainer', 'ruleName', 'listType', 'addTagsLink', 'addTagsSection', 'treeList', 'editSection', 'listView'];

  connect() {
    // this.fillResponderLists(this.listTarget);
  }

  fillResponderLists(el) {
    var options = $("#responder-mailing-list > option").clone();
    $(el).html(options);
  }

  addMoreImportLists(e) {
    const listIndex = $('.import-list-select').length;
    this.addList(e, listIndex)
  }

  addMoreExportLists(e) {
    const listIndex = $('.export-list-select').length;
    this.addList(e, listIndex)
  }

  addList(e, listIndex) {
    e.preventDefault();
    const url = this.selectContainerTarget.dataset.addMoreImportListUrl;
    const listType = this.selectContainerTarget.dataset.listType;
    const bundleChildIndex = this.selectContainerTarget.dataset.bundleChildIndex;
    const $container = $(this.selectContainerTarget);
    $.get(url, {
      bundle_index: bundleChildIndex,
      index: listIndex,
      list_type: listType,
    }).then(function (form) {
      const items = $('.import-list-section', form);
      const item = $('select.select2-search', items);
      $container.prepend(items);
      this.toggleEditView();
      $(item).select2({
        theme: "bootstrap",
        width: 'resolve',
        placeholder: 'בחירת רשימה',
        dir: 'rtl',
        language: {
          noResults: function(){
           return 'לא נמצאה רשימה';
          }
        }
      }).on('select2:open', function(e){
        $('.select2-search__field').attr('placeholder', 'חיפוש רשימה');
        $('.select2-search__field').each(function() {
          this.style.setProperty('width', '100%', 'important');
        });
      });
    }.bind(this))
    .fail(function (err) {
      alert(I18n.unknown_add_course_error);
      console.log(err);
    });
  }

  updateListSelect() {
    var options = [];
    if(this.listTypeTarget.value == ''){
      return
    }
    if(this.listTypeTarget.value == "ImportSource::RavMeser::V1" || this.listTypeTarget.value == "ExportDestination::RavMeser::V1"){
      options = $("#responder-mailing-list > option").clone();
      if(this.hasAddTagsLinkTarget){
        $(this.addTagsLinkTarget).hide();
        $(this.addTagsSectionTarget).hide();
      }
      if(this.hasAddTagsSectionTarget){
        $(this.treeListTarget).after($(this.addTagsSectionTarget));
        $(this.addTagsSectionTarget).hide();
      }
    }else{
      if(this.listTypeTarget.value == "ImportSource::RavMeser::V2"){
        options = $("#responder-mailing-list-v2 > option").clone();
      }else if(this.listTypeTarget.value == "ExportDestination::RavMeser::V2"){
        options = $("#responder-mailing-list-v2 [data-is-dynamic=0]").clone();
      }
      if(this.hasAddTagsLinkTarget){
        $(this.addTagsLinkTarget).show();
      }
    }
    options = this.filteredOptions(options);
    $(this.listTarget).html(options);
  }

  toggleEditView(){
    this.editSectionTarget.hidden = false;
    this.listViewTarget.classList.add('hidden-important');
  }

  filteredOptions(options){
    var selectedLists = [];
    if(["ImportSource::RavMeser::V1", "ImportSource::RavMeser::V2"].includes(this.listTypeTarget.value) ){
      selectedLists = this.listViewTargets.map( (item) =>
        $(item).find('#import_list').data('value')
      ).filter( Boolean );
    }else{
      selectedLists = this.listViewTargets.map( function(item) {
        if($(item).find("#export_rule_id").data('value') == this.ruleNameTarget.value){
          return $(item).find("#export_list").data('value')
        }
      }.bind(this));
    }

    var data = options.map(function(index, item) {
      if(selectedLists.includes(parseInt(item.value))){
          item.disabled = true;
      }
      return item;
    })
    return data;
  }
}
