import { Controller } from "stimulus";
import $ from 'jquery';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['modalDelete', 'modalDeleteBackdrop', 'deleteButton', 'feedbackError', 'inputCourseName'];
  initialize() {
    this.keyUpEvent = debounce(this.courseNameKeyUp.bind(this), 500);
    if(this.inputCourseNameTarget) {
      this.inputCourseNameTarget.addEventListener('keyup', this.keyUpEvent);
      this.inputCourseNameTarget.addEventListener('keypress', this.keyPressEvent);
    }
  }
  disconnect(){
    this.inputCourseNameTarget.removeEventListener('keyup', this.keyUpEvent);
    this.inputCourseNameTarget.removeEventListener('keypress', this.keyPressEvent);
  }
  keyPressEvent(e){
    if(e.which === 13/*Enter*/){
      e.preventDefault();
    }
  }
  courseNameKeyUp(e) {
    const input = e.target;
    this.setElementsState(input.value !== input.dataset.courseName);
  }
  setElementsState(value) {
    if(value){
      this.deleteButtonTarget.classList.add('disabled');
      this.deleteButtonTarget.href = 'javascript:void(0)';
    } else {
      this.deleteButtonTarget.classList.remove('disabled');
      this.deleteButtonTarget.href = this.deleteButtonTarget.dataset.route;
    }
    this.feedbackErrorTarget.style.visibility = value && this.inputCourseNameTarget.value ? 'visible' : 'hidden';
  }

  showConfirmationDialog(e) {
      this.inputCourseNameTarget.value = '';
      this.setElementsState(true);
      $(this.modalDeleteTarget).addClass('show').show();
      $(this.modalDeleteBackdropTarget).show();
      e.preventDefault();
  }

  hideModal(e) {
    $(this.modalDeleteTarget).add(this.modalDeleteBackdropTarget).hide();
  }

  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.hideModal(e);
  }
}
