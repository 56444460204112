import { Controller } from 'stimulus';
import $ from 'jquery';
import { CUSTOM_EVENT_NAMES } from '../../../services/meshulam_service';
import { dispatchCustomEvent } from '../../../common';

export default class ModalInfoController extends Controller {
    static targets = ['modalOption', 'saveButton']

    initialize() {
        this.$modalOption = $(this.modalOptionTarget)
        this.$modalOptionBackdrop = $('.meshulam-backdrop')
    }

    showModal(e) {
        this.$modalOption.addClass('show')
            .data('businessId',e.detail.businessId)
            .add(this.$modalOptionBackdrop)
            .show();

    }

    hideModal(e) {
        this.$modalOption.removeClass('show')
            .add(this.$modalOptionBackdrop)
            .hide();
    }

    outsideClick(e) {
        if (e.target === e.currentTarget)
            this.hideModal(e);
    }

    approvedDelete(e) {
        dispatchCustomEvent(CUSTOM_EVENT_NAMES.APPROVED_DELETE, { businessId: this.$modalOption.data('businessId') })
        this.$modalOption.removeData('businessId')
        this.hideModal(e);
    }
}
