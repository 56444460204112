import { Controller } from 'stimulus';
import termService from '../../../services/term_service';
import { controlOfDisableElements } from '../../../common';

export default class extends Controller {
  static targets = ['modalOptionLink', 'linkAction', 'clearAction', 'termsWrapper']

  initialize() {
    this.currentSelection = null;
    document.addEventListener('selectionchange', this.selectionChanged.bind(this));
  }
  disconnect(){
    document.removeEventListener('selectionchange', this.selectionChanged.bind(this));
  }
  
  selectTextInHtmlComponent(){
    const {startNode, endNode, startNumber, endNumber} = termService.currentSelection;
    const range = document.createRange()
    const selection = window.getSelection();
    if(startNode.isSameNode(endNode)){
      const start = Math.min(startNumber, endNumber)
      const end = Math.max(startNumber, endNumber)
      range.setStart(startNode, start);
      range.setEnd(startNode, end);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      range.setStart(startNode, startNumber);
      range.setEnd(endNode, endNumber);
      selection.removeAllRanges();
      selection.addRange(range);
      // if did not selected text, try change start and end positions
      if (selection.type !== 'Range') {
        range.setStart(endNode, endNumber);
        range.setEnd(startNode, startNumber);
      }
    }
  }

  selectionChanged(e) {
    const modal = document.querySelector('.modal-terms')
    if (modal && !modal.classList.contains('show')) {
      const isSelectionInsideWrapper = document.activeElement.isSameNode(this.termsWrapperTarget)
      const selectedText = termService.getSelectedText();
      if (isSelectionInsideWrapper && selectedText.toString()) {
        termService.CurrentSelection = selectedText
        const isAnchorSelected = termService.getSelectedAnchors(termService.getSelectedText(), this.termsWrapperTarget).length !== 0
        controlOfDisableElements(isAnchorSelected, this.linkActionTarget)
      } else if (isSelectionInsideWrapper && selectedText.type === 'Caret') {
        termService.CurrentSelection = null
        controlOfDisableElements(true, this.linkActionTarget)
      }
      else if (this.termsWrapperTarget.innerText && termService.CurrentSelection && !termService.CurrentSelection.startNode.isSameNode(selectedText.anchorNode)
        && !this.termsWrapperTarget.contains(selectedText.anchorNode)) {
        this.selectTextInHtmlComponent()
      }
    }
  }
}
