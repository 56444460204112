import { Controller } from 'stimulus';
import { copyTextToClipboard } from '../../services/copy_text_to_clipboard';

export default class extends Controller {
  static targets = ['slugInput', 'prefix'];

  copyToClipboard(event) {
    event.preventDefault();

    let prefix = this.prefixTarget.innerHTML;
    let slug = this.slugInputTarget.value || this.slugInputTarget.innerHTML;

    let copiedSuccessfully = copyTextToClipboard(prefix + slug);

    if (copiedSuccessfully) {
      $('.notifyjs-wrapper').trigger('notify-hide');
      $(this.slugInputTarget).parent().notify(I18n.copy_ok || '', 'success');
    }
  }
}
