import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = ['resListTitle'];

  connect() {
    this.listsFromResponderAPI = null;

    if(this.element.dataset.listUrl !== undefined){
      $.ajax({type: "GET", url: this.element.dataset.listUrl,
        success: (data) => {
          this.listsFromResponderAPI = data.list
          this.replaceIdWithTitle()
        },
        error: (data, textStatus, err) => {
          console.log(err)
        }
      })
    }

    this.listsFromNewResponderAPI = null;
    if(this.element.dataset.listUrlNewSystem !== undefined){
      $.ajax({type: "GET", url: this.element.dataset.listUrlNewSystem,
        success: (data) => {
          this.listsFromNewResponderAPI = data.list
          this.replaceNewIdWithTitle()
        },
        error: (data, textStatus, err) => console.log(err)
      })
    }
  }

  replaceIdWithTitle() {
    if (this.hasResListTitleTarget) {
      for (let i = 0; i < this.resListTitleTargets.length; i++) {
        let listId = this.resListTitleTargets[i].innerHTML;
        let listObj = this.listsFromResponderAPI.find(o => o.ID === listId)

        if (listObj !== undefined) {
          this.resListTitleTargets[i].innerHTML = listObj.DESCRIPTION;
        }
      }
    }
  }

  replaceNewIdWithTitle() {
    if (this.hasResListTitleTarget) {
      for (let i = 0; i < this.resListTitleTargets.length; i++) {
        let listId = this.resListTitleTargets[i].innerHTML;
        let listObj = this.listsFromNewResponderAPI.find(o => o.id == listId)

        if (listObj !== undefined) {
          this.resListTitleTargets[i].innerHTML = listObj.name;
        }
      }
    }
  }
}
