import ResponderListsController from './responder_lists_controller';
import $ from 'jquery';


export default class extends ResponderListsController {
  static targets = ['resListTitle'];

  connect() {
    super.connect();
  }

  fillResponderLists(ev) {
    super.fillResponderLists(ev);

    var options = $("#responder-mailing-list > option").clone();
    $("select.mailing-lists").each(function() {
      $(this).html(options);
      $(this).val($(this).data().value);
    });
    this.replaceIdWithTitle(options, false);
  }

  fillResponderListsNewSystem(ev) {
    super.fillResponderListsNewSystem(ev);

    var options = $("#responder-mailing-list-v2 > option").clone();;
    $("select.mailing-lists-v2").each(function() {
      $(this).html(options);
      $(this).val($(this).data().value);
    });
    this.replaceIdWithTitle(options, true);
  }

  fillResponderTagsNewSystem(ev) {
    super.fillResponderTagsNewSystem(ev);
    $(this).val($("#v2-tags").val());
  }

  replaceIdWithTitle(options, new_system) {
    if(options.length == 0){
      return
    }
    if(this.hasResListTitleTarget){
      var targets = $(this.resListTitleTargets).filter(`[data-new-system="${new_system}"]`);
      for (let i=0; i < targets.length; i++) {
        let list_id = targets[i].dataset.value;
        if(list_id !== undefined){
          let list_name = options.filter(`[value='${list_id}']`).text()
          if(list_name !== ""){
            targets[i].innerHTML = list_name;
          }else{
            targets[i].innerHTML = "<text style='color:red;''>החיבור לרשימה נותק</text>";
          }
        }
      }
    }
  }
}
