import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'form',
    'toolbar',
    'itemCheckbox',
    'scopeCheckbox',
    'selectedClearButton',
    'selectedCountMessage',
    'selectAllInAllPageButton',
    'confirmationModal',
    'confirmationModalBackdrop',
    'confirmationModalDeletion',
    'confirmationModalInactive',
    'confirmationModalDeleteButton',
    'confirmationModalSelectedCountInput',
    'selectedCountAllMessage',
    'splitColumn1',
    'splitColumn2',
    'wrapScopeCheckBox',
    'checkBoxinHead',
  ];

  selectedCountInputKeyUp(e) {
    this.updateConfirmationModalElementsState(e.target.value === `${this.scopeSelectedCount()}`);
  }

  updateInactiveConfirmationModalElementsState() {
    const selectedCount = this.scopeSelectedCount();
    const subTitleTarget = this.confirmationModalTarget.querySelector('.subtitleInactive');
    const bodyContentTarget = this.confirmationModalTarget.querySelector('.body-content');
    const schoolIdTarget = this.formTarget.querySelector('#school_id').value
    const constrollerName = this.formTarget.querySelector('#controller_name').value

    if (subTitleTarget) {
      if (selectedCount == 1) {
        subTitleTarget.innerHTML = schoolIdTarget ? subTitleTarget.getAttribute('data-content-course-1') : subTitleTarget.getAttribute('data-content');
      } else {
        subTitleTarget.innerHTML = schoolIdTarget ? subTitleTarget.getAttribute('data-content-course-n') : subTitleTarget.getAttribute('data-content-n');
      }
    }

    if (bodyContentTarget) {
      if (selectedCount == 1) {
        bodyContentTarget.innerHTML = bodyContentTarget.getAttribute('data-content-delete-1');
      } else {
        if (constrollerName == 'users') {
          bodyContentTarget.innerHTML = bodyContentTarget.getAttribute('data-content-delete-school-n')
        } else {
          bodyContentTarget.innerHTML = schoolIdTarget ? bodyContentTarget.getAttribute('data-content-delete-course-n') : bodyContentTarget.getAttribute('data-content-delete-n');
        }
      }
    }
  }

  showInactiveConfirmationModal(e) {
    e.preventDefault();

    this.updateInactiveConfirmationModalElementsState();
    $(this.confirmationModalTarget).addClass('show').show();
    $(this.confirmationModalBackdropTarget).show();
    $(this.confirmationModalDeletionTarget).hide();
    $(this.confirmationModalInactiveTarget).show();
  }

  updateConfirmationModalElementsState(matched, resetSelectedInput = false) {
    const selectedCount = this.scopeSelectedCount();
    const subTitleTarget = this.confirmationModalTarget.querySelector('.subTilteDeletion');

    if (subTitleTarget) {
      subTitleTarget.innerHTML = (subTitleTarget.getAttribute('data-content') || '').replace('%{count}', selectedCount);
    }
    if (resetSelectedInput) {
      this.confirmationModalSelectedCountInputTarget.value = '';
    }
    this.confirmationModalSelectedCountInputTarget.placeholder = `${selectedCount}`;
    this.confirmationModalDeleteButtonTarget.classList[matched ? 'remove' : 'add']('disabled');
  }

  showConfirmationModal(e) {
    e.preventDefault();

    this.updateConfirmationModalElementsState(false, true);
    $(this.confirmationModalTarget).addClass('show').show();
    $(this.confirmationModalInactiveTarget).hide();
    $(this.confirmationModalBackdropTarget).show();
    $(this.confirmationModalDeletionTarget).show();
  }

  hideConfirmationModal(e) {
    $(this.confirmationModalTarget)
      .add(this.confirmationModalBackdropTarget)
      .hide();
  }

  outsideConfirmationModalClick(e) {
    if (e.target === e.currentTarget) {
      this.hideConfirmationModal(e);
    }
  }

  submitDestroy(e) {
    e.preventDefault();

    if (!e.target.classList.contains('disabled')) {
      this.formTarget.querySelector('#destroy').value = 'true'
      this.formTarget.submit();
    }
  }

  submitInactive(e) {
    e.preventDefault();

    if (!e.target.classList.contains('disabled')) {
      this.formTarget.submit();
    }
  }

  toggleAllItem(e) {
    const checked = e.target.checked;

    this.itemCheckboxTargets.forEach((cb) => {
      cb.checked = checked;
    });

    if (!this.checkBoxinHeadTarget.checked) this.scopeCheckboxTarget.checked = false;
    this.setScopeCheckboxValue('single_page');
    this.updateToolbarElementsState();
  }

  toggleAllItemAllPage(e) {
    const checked = e.target.checked;

    this.itemCheckboxTargets.forEach((cb) => {
      cb.checked = checked;
    });
    this.setScopeCheckboxValue('all_page');
    this.updateToolbarElementsState();
  }

  toggleItem(e) {
    const singlePageAllItemCount = this.singlePageAllItemCount();
    const singlePageSelectedItemCount = this.singlePageSelectedItemCount();

    if (!e.target.checked) this.scopeCheckboxTarget.checked = false;
    this.setScopeCheckboxValue('single_page');
    this.updateToolbarElementsState();
  }

  selectAllInAllPage(e) {
    e.preventDefault();

    this.setScopeCheckboxValue('all_page');
    this.updateToolbarElementsState();
  }

  clearSelected(e) {
    e.preventDefault();

    this.setScopeCheckboxValue('single_page');
    this.updateToolbarElementsState();
  }

  setScopeCheckboxValue(value) {
    this.scopeCheckboxTarget.value = value;
  }

  updateToolbarElementsState() {
    const singlePageSelectedItemCount = this.singlePageSelectedItemCount();
    const scopeSelectedCount = this.scopeSelectedCount();
    const scopeValue = this.scopeCheckboxTarget.value;

    this.selectedCountMessageTarget.innerHTML = (
      this.selectedCountMessageTarget.getAttribute(
        scopeValue === 'single_page'
          ? (scopeSelectedCount === 1 ? 'data-selected-1' : 'data-selected-n')
          : 'data-selected-all'
      ) || ''
    ).replace('%{count}', scopeSelectedCount);

    this.selectedCountAllMessageTarget.innerHTML = this.selectedCountAllMessageTarget.getAttribute('data-value')

    this.selectedCountMessageTarget.classList[
      singlePageSelectedItemCount > 0 ? 'remove' : 'add'
    ]('hidden');

    this.toolbarTarget.classList[
      singlePageSelectedItemCount > 0 ? 'remove' : 'add'
    ]('hidden');

    this.selectedCountAllMessageTarget.classList[
      singlePageSelectedItemCount > 0 ? 'remove' : 'add'
    ]('hidden');

    this.splitColumn1Target.classList[
      singlePageSelectedItemCount > 0 ? 'remove' : 'add'
    ]('hidden');

    this.splitColumn2Target.classList[
      singlePageSelectedItemCount > 0 ? 'remove' : 'add'
    ]('hidden');

    this.wrapScopeCheckBoxTarget.classList[
      singlePageSelectedItemCount > 0 ? 'remove' : 'add'
    ]('hidden');

    this.checkBoxinHeadTarget.checked = singlePageSelectedItemCount > 0 ? true : false
  }

  singlePageAllItemCount() {
    return this.itemCheckboxTargets.length;
  }

  singlePageSelectedItemCount() {
    return this.itemCheckboxTargets.reduce((counter, cb) => counter + (cb.checked ? 1 : 0), 0);
  }

  allPageAllItemCount() {
    return parseInt(this.selectAllInAllPageButtonTarget.getAttribute('data-count')) || 0;
  }

  scopeSelectedCount() {
    switch (this.scopeCheckboxTarget.value) {
      case 'single_page':
        return this.singlePageSelectedItemCount();
      case 'all_page':
        return this.allPageAllItemCount();
      default:
        return 0;
    }
  }
}
