import  DynamicTooltipController from "./dynamic_tooltip_controller";
import { meshulamService, USER_PROCESS_STATUS, CUSTOM_EVENT_NAMES } from '../../services/meshulam_service';
import { hideOrShowElements, hideOrShowElementsByCondition, dispatchCustomEvent,
    controlOfDisableElements, schoolerFetch} from '../../common';

const SPINNER_CLASS_NAME = 'pending-spinner'

export default class extends DynamicTooltipController {
    static targets = ['paymentsContainer', 'selectPayments', 'regularPaymentRadio','paymentsWrapper',
        'errorMessageContainer', 'businessIdInput', 'meshulamConnectBtn', 'creditCheckbox',
        'bitCheckbox', 'notificationTemplate', 'phoneNumberContainer', 'businessIdHiddenInput','businessIdContainer',
        'footerContainer', 'dropdownSelectedText', 'paymentCreditTypesContainer', 'titleContainer', 'dividePaymentRadio',
        'phoneNumberInput'];

    initialize() {
        hideOrShowElementsByCondition(true, this.paymentsContainerTarget, this.paymentsWrapperTarget)
        this.notificationWrapper = document.querySelector('body > .wrapper .content')
      }

    connect() {
        controlOfDisableElements(parseInt(this.element.dataset.isFormInvalid) !== 1, this.selectPaymentsTarget)
        this.paymentContainerState()
    }

    //data fire custom event from ws Meshulam chanel
    meshulamDataReceived(e) {
        meshulamService.createOrUpdateTopStatusStrip({ status: e.detail.status, isDisplayStrip: true },
            this.errorMessageContainerTarget, this.notificationWrapper, this.notificationTemplateTarget.innerHTML)

        const isSuccessStatus = e.detail.status === USER_PROCESS_STATUS.SUCCESS;

        meshulamService.updatePaymentTitle(this.titleContainerTarget, e.detail.businessId, e.detail.status)

        hideOrShowElementsByCondition(!isSuccessStatus, this.paymentsWrapperTarget)
        hideOrShowElementsByCondition(isSuccessStatus, this.phoneNumberContainerTarget, this.businessIdContainerTarget, this.meshulamConnectBtnTarget)
        // this.setDefaultValues(isSuccessStatus)
        this.setResponseValues(e.detail.isBitAuthorized, e.detail.isCreditCardAuthorized)
    }

    setResponseValues(isBit, isCreditCard){
        this.bitCheckboxTarget.checked = isBit
        this.creditCheckboxTarget.checked = isCreditCard
        this.regularPaymentRadioTarget.checked = true
        hideOrShowElementsByCondition(!isCreditCard, this.paymentCreditTypesContainerTarget)
        controlOfDisableElements(isBit && !isCreditCard, this.dividePaymentRadioTarget, this.dividePaymentRadioTarget.parentNode, this.regularPaymentRadioTarget)
    }

    businessIdOrPhoneNumberChanged(e) {
        if(this.businessIdInputTarget.isSameNode(e.target)){
            this.businessIdHiddenInputTarget.value = e.target.value
        }
        this.meshulamConnectBtnTarget.disabled = !this.businessIdInputTarget.value || this.phoneNumberInputTarget.value.length < 10
    }

    paymentChanged(e) {
      const { dataset: selectedDataset } = this.dropdownSelectedTextTarget
        const {checked: isCreditChecked} = this.creditCheckboxTarget
        const {checked: isBitChecked} = this.bitCheckboxTarget
        const isBitSelected = !isCreditChecked || isBitChecked
        const isCreditSelected = !isBitChecked || isCreditChecked
        const isBitAuthorized = selectedDataset.isBitAuthorized && JSON.parse(selectedDataset.isBitAuthorized)
        const isCreditAuthorized = selectedDataset.isCreditCardAuthorized && JSON.parse(selectedDataset.isCreditCardAuthorized)

        if(this.bitCheckboxTarget.isSameNode(e.target) && !isBitAuthorized){
          this.bitCheckboxTarget.checked = false
          return
        }
        if(this.creditCheckboxTarget.isSameNode(e.target) && !isCreditAuthorized){
          this.creditCheckboxTarget.checked = false
          return
        }


        if (!isCreditChecked && !isBitChecked) {
            if(isCreditAuthorized) {
                this.creditCheckboxTarget.checked = true
            } else if(isBitAuthorized){
                this.bitCheckboxTarget.checked = true
            }
        }

        if (isBitSelected && isBitAuthorized) {
            this.updatedPaymentContainer(true)
            this.regularPaymentRadioTarget.checked = true
        }

        controlOfDisableElements(isBitChecked, this.dividePaymentRadioTarget, this.dividePaymentRadioTarget.parentNode, this.regularPaymentRadioTarget)
        hideOrShowElementsByCondition(!isCreditChecked && isBitChecked, this.paymentCreditTypesContainerTarget)
        hideOrShowElementsByCondition(!this.creditCheckboxTarget.checked , this.paymentCreditTypesContainerTarget)
    }

    paymentCreditChanged(e) {
        if((!this.creditCheckboxTarget.checked && !this.regularPaymentRadioTarget.checked)
            ||(this.creditCheckboxTarget.checked && this.bitCheckboxTarget.checked)){
            this.regularPaymentRadioTarget.checked = true
            controlOfDisableElements(true, this.dividePaymentRadioTarget, this.dividePaymentRadioTarget.parentNode)
        } else {
            controlOfDisableElements(false, this.dividePaymentRadioTarget, this.dividePaymentRadioTarget.parentNode)
        }

        this.updatedPaymentContainer(this.regularPaymentRadioTarget.checked)
    }

    businessIdChanged(e) {
        const li = e.target.closest('li')
        const { value: businessId, status, isBitAuthorized, isCreditCardAuthorized } = li.dataset
        const isSelectedAddNewOption  = businessId === '-1'
        this.setBitAndDividePaymentState(isBitAuthorized && !JSON.parse(isBitAuthorized))
        this.setCreditCardPaymentState(isCreditCardAuthorized && !JSON.parse(isCreditCardAuthorized))

        meshulamService.updatePaymentTitle(this.titleContainerTarget, businessId, status)

        if (isSelectedAddNewOption) {
            this.businessIdHiddenInputTarget.value = ''
            this.businessIdInputTarget.value = ''
            this.phoneNumberInputTarget.value = ''
            hideOrShowElements([this.paymentsWrapperTarget, this.errorMessageContainerTarget],
                 [this.phoneNumberContainerTarget, this.businessIdContainerTarget, this.meshulamConnectBtnTarget, this.footerContainerTarget])
        } else {
            this.setDefaultValues()
            this.updateElements(businessId, status)
            controlOfDisableElements(!this.creditCheckboxTarget.checked, this.dividePaymentRadioTarget, this.dividePaymentRadioTarget.parentNode, this.regularPaymentRadioTarget)
            hideOrShowElementsByCondition(!this.creditCheckboxTarget.checked, this.paymentCreditTypesContainerTarget)
            if(JSON.parse(isBitAuthorized) && !JSON.parse(isCreditCardAuthorized)){
                hideOrShowElementsByCondition(true, this.paymentCreditTypesContainerTarget)
                this.creditCheckboxTarget.checked = false
                this.bitCheckboxTarget.checked = true
            }
        }
    }

    creditDivisionPaymentsMouseEnter(e) {
        if (this.creditCheckboxTarget.disable || this.creditCheckboxTarget.parentNode.hasAttribute('disabled')) {
            super.mouseOver(e)
        } else if(e.target.querySelector('.nm-tooltip')){
            e.target.removeChild(e.target.querySelector('.nm-tooltip'))
            e.target.classList.remove('nm-tooltiped')
        }
    }

    bitContainerMouseEnter(e) {
      if (this.bitCheckboxTarget.disabled || this.bitCheckboxTarget.parentNode.hasAttribute('disabled')) {
          super.mouseOver(e)
      } else if(e.target.querySelector('.nm-tooltip')){
          e.target.removeChild(e.target.querySelector('.nm-tooltip'))
          e.target.classList.remove('nm-tooltiped')
      }
    }
    updatedPaymentContainer(isHide) {
        hideOrShowElementsByCondition(isHide, this.paymentsContainerTarget)
        controlOfDisableElements(false, this.selectPaymentsTarget)
        this.selectPaymentsTarget.value = '1'
    }

    paymentContainerState() {
        const { paymentInfoInUnclosedStatus } = this.element.dataset
        const { firstElementChild: businessIdElement, dataset: selectedDataset } = this.dropdownSelectedTextTarget
        const selectedValue = businessIdElement.dataset.value
        const isFormInvalid = parseInt(this.element.dataset.isFormInvalid) > 0

        meshulamService.updatePaymentTitle(this.titleContainerTarget, selectedValue, selectedDataset.status)
        // this.setBitAndDividePaymentState(selectedDataset.isBitAuthorized && !JSON.parse(selectedDataset.isBitAuthorized))
        // this.setCreditCardPaymentState(selectedDataset.isCreditCardAuthorized && !JSON.parse(selectedDataset.isCreditCardAuthorized))

        if (selectedValue && Number(selectedDataset.status) === USER_PROCESS_STATUS.SUCCESS) {
            this.setDefaultValues(!isFormInvalid)
            this.updateElements(selectedValue, USER_PROCESS_STATUS.SUCCESS)
        } else if (paymentInfoInUnclosedStatus === '') {
            hideOrShowElements(this.paymentsWrapperTarget, [this.phoneNumberContainerTarget, this.businessIdContainerTarget])
        } else if (paymentInfoInUnclosedStatus && USER_PROCESS_STATUS.DELETED !== +paymentInfoInUnclosedStatus) {
            this.updateElements(businessIdElement.innerText || -1, paymentInfoInUnclosedStatus)
        }
    }

    updateElements(businessId, status){
        const statusAsNumber = parseInt(status)
        meshulamService.createOrUpdateTopStatusStrip({ status: statusAsNumber, isDisplayStrip :statusAsNumber !== USER_PROCESS_STATUS.SUCCESS },
            this.errorMessageContainerTarget, this.notificationWrapper, this.notificationTemplateTarget.innerHTML)

        this.businessIdHiddenInputTarget.value = statusAsNumber !== USER_PROCESS_STATUS.DELETED ? businessId : ''

        hideOrShowElementsByCondition(this.regularPaymentRadioTarget.checked, this.paymentsContainerTarget)
        controlOfDisableElements(this.regularPaymentRadioTarget.checked, this.selectPaymentsTarget)

        if (statusAsNumber === USER_PROCESS_STATUS.ERROR) {
            hideOrShowElements([this.paymentsWrapperTarget, this.phoneNumberContainerTarget, this.businessIdContainerTarget, this.meshulamConnectBtnTarget], [ this.footerContainerTarget, this.errorMessageContainerTarget])
        } else if (statusAsNumber === USER_PROCESS_STATUS.SUCCESS) {
            hideOrShowElements([this.phoneNumberContainerTarget, this.footerContainerTarget, this.businessIdContainerTarget], this.paymentsWrapperTarget)
        } else {
            hideOrShowElements([this.phoneNumberContainerTarget, this.footerContainerTarget, this.businessIdContainerTarget, this.paymentsWrapperTarget])
        }
    }

    validateUserId() {
        controlOfDisableElements(true, this.meshulamConnectBtnTarget)
        this.meshulamConnectBtnTarget.parentNode.classList.add(SPINNER_CLASS_NAME)

        const data = { businessId: this.businessIdInputTarget.value, account : this.accountIdInputTarget.value }
        schoolerFetch(this.element.dataset.userValidateUrl, data, true, 'json', 'POST')
        .then(res => {
            this.meshulamConnectBtnTarget.parentNode.classList.remove(SPINNER_CLASS_NAME)
            if (res.status === USER_PROCESS_STATUS.SUCCESS) {
                hideOrShowElementsByCondition(false, this.paymentsWrapperTarget)
            } else if (res.status === USER_PROCESS_STATUS.PENDING){
                dispatchCustomEvent(CUSTOM_EVENT_NAMES.INFO_DIALOG, { open: true })
            }
            if (res.status !== USER_PROCESS_STATUS.DELETED) {
             dispatchCustomEvent(CUSTOM_EVENT_NAMES.DATA_RECEIVED, { status: res.status, businessId: this.businessIdInputTarget.value } )
            }
        })
    }

    onGetUserId() {
      controlOfDisableElements(true, this.meshulamConnectBtnTarget)
      this.meshulamConnectBtnTarget.parentNode.classList.add(SPINNER_CLASS_NAME)

      const data = { businessId: this.businessIdInputTarget.value, phone_number : this.phoneNumberInputTarget.value }
      schoolerFetch(this.element.dataset.userIdUrl, data, true, 'json')
      .then(res => {
          this.meshulamConnectBtnTarget.parentNode.classList.remove(SPINNER_CLASS_NAME)
          if (res.status === USER_PROCESS_STATUS.SUCCESS) {
              hideOrShowElementsByCondition(false, this.paymentsWrapperTarget)
          } else if (res.status === USER_PROCESS_STATUS.PENDING){
              dispatchCustomEvent(CUSTOM_EVENT_NAMES.INFO_DIALOG, { open: true })
          }
          if (res.status !== USER_PROCESS_STATUS.DELETED) {
           dispatchCustomEvent(CUSTOM_EVENT_NAMES.DATA_RECEIVED, { status: res.status, businessId: this.businessIdInputTarget.value, isBitAuthorized: res.isBitAuthorized, isCreditCardAuthorized: res.isCreditCardAuthorized} )
          }

          this.setBitAndDividePaymentState(!res.isBitAuthorized)
          this.setCreditCardPaymentState(!res.isCreditCardAuthorized)
          hideOrShowElementsByCondition(res.isBitAuthorized && !res.isCreditCardAuthorized, this.paymentCreditTypesContainerTarget)
        })
      }
      setDefaultValues(isUpdateValues = true) {
        if(isUpdateValues) {
          this.creditCheckboxTarget.checked = true
          this.bitCheckboxTarget.checked = false
          this.regularPaymentRadioTarget.checked = true
          this.selectPaymentsTarget.value = '1'
        } else if (this.bitCheckboxTarget.checked) {
          this.paymentChanged()
        }
   }

    setBitAndDividePaymentState(isDisabled) {
      controlOfDisableElements(isDisabled, this.bitCheckboxTarget, this.bitCheckboxTarget.parentNode)
    }

    setCreditCardPaymentState(isDisabled) {
      controlOfDisableElements(isDisabled, this.creditCheckboxTarget, this.creditCheckboxTarget.parentNode)
    }
}
