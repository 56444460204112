import { Controller } from 'stimulus';
import termService from '../../../services/term_service';

export default class extends Controller {
    static targets = ['termLinkOptionsContainer', 'linkPreview', 'removeLink', 'termsWrapper', 'termsHiddenInput']

    initialize() {
        document.addEventListener('click', this.documentClicked.bind(this))
    }

    disconnect() {
        document.removeEventListener('click', this.documentClicked.bind(this))
    }
    //for hiding the options link container
    documentClicked({target}){
        if(!((this.termsWrapperTarget.contains(target) && target.tagName === 'A')
            || target === this.termLinkOptionsContainerTarget
            || this.termLinkOptionsContainerTarget.contains(target))){
            this.updateContainerPosition()
        }
    }

    termsClick(e) {
        const { innerText, tagName, href, dataset } = e.target;
        if (tagName === 'A') {
            const { bottom: anchorBottomPosition, left: anchorLeftPosition } = e.target.getBoundingClientRect()
            const { width: termLinkOptionsContainerWidth } = this.termLinkOptionsContainerTarget.getBoundingClientRect();
            if(dataset.blobId !== '-1') {
                this.linkPreviewTarget.innerHTML = innerText
                const {termsAttachmentsUrl} = this.termLinkOptionsContainerTarget.dataset;
                termService.getAttachmentUrl(termsAttachmentsUrl, dataset.blobId)
                .then((res) => (this.linkPreviewTarget.href = res ? res.url : '#'))
            } else {
                this.linkPreviewTarget.innerHTML = href
                this.linkPreviewTarget.title = href
                this.linkPreviewTarget.href = href
            }
            this.removeLinkTarget.dataset.blobId = dataset.blobId
            this.updateContainerPosition(`${anchorBottomPosition + window.scrollY}px`,
                `${anchorLeftPosition + window.scrollX - termLinkOptionsContainerWidth}px`)
        } else {
            this.updateContainerPosition()
        }
    }

    removeLinkClicked(e) {
        const wrapper = this.termsWrapperTarget;
        let isRemovedLink = false;
        const pdfInput = document.querySelector('.pdf-file-input')
        wrapper.childNodes.forEach(function (node) {
            if (node.nodeName === 'A' && node.dataset.blobId === e.target.dataset.blobId) {
                const result = termService.deleteHiddenWithBlobId(node.dataset.blobId)
                if(!result && this.termLinkOptionsContainerTarget.dataset.schoolId){
                  termService.markAsDeleteAttachment(pdfInput.name, node.dataset.blobId)
                }
                wrapper.insertBefore(document.createTextNode(node.innerText), node)
                wrapper.removeChild(node)
                isRemovedLink = true
            }
        }.bind(this))

        if (isRemovedLink) {
            this.termsHiddenInputTarget.value = this.termsWrapperTarget.innerHTML.trim()
        }
        this.updateContainerPosition()
    }

    updateContainerPosition(top = '-9999px', left = '-9999px') {
        Object.assign(this.termLinkOptionsContainerTarget.style, { top, left })
    }
}
