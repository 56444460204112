import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = ['modalDeleteList', 'modalDeleteBackdrop', 'deleteListMessage',
                    'notImportedSection', 'importedSection', 'importStatusCheckbox', 'list', 'listType'];

  toggleDestroyList(e) {
    e.preventDefault();
    const $container = $(this.element);
    $container.hide();

    if($container.find('.edit-list-id')[0].value == ''){
      $container.remove();
    }else{
      const destroyInput = $container.find('input.destroy');
      destroyInput.prop('disabled', false);
      $container.find('.list-display')[0].classList.add('deleted');
    }
  }

  showConfirmationDialog(e) {
      $(this.modalDeleteListTarget).addClass('show').show();
      $(this.modalDeleteBackdropTarget).show();
      var listName = ''
      if(this.listTypeTarget.value == "ImportSource::RavMeser::V1" || this.listTypeTarget.value == "ExportDestination::RavMeser::V1"){
        listName = $(`#responder-mailing-list option[value=${this.listTarget.dataset.value}]`).text()
      }else{
        listName = $(`#responder-mailing-list-v2 option[value=${this.listTarget.dataset.value}]`).text()
      }
      // const selectField = this.listTarget
      // const listName = selectField.options[selectField.options.selectedIndex].text
      // const $fieldset = $(e.target).closest('fieldset');
      // const listName = $($fieldset[0]).find('select option:selected').text();
      $(this.deleteListMessageTarget).html(`אתם עומדים למחוק את החיבור לרשימה <b>${listName}</b>`);
      e.preventDefault();
  }

  hideModal(e) {
    $(this.modalDeleteListTarget).add(this.modalDeleteBackdropTarget).hide();
  }

  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.hideModal(e);
  }

  showImportCheck(e) {
    $(this.notImportedSectionTarget).show();
    $(this.importedSectionTarget).hide();
    $(this.importStatusCheckboxTarget).val(1);
    $(this.importStatusCheckboxTarget).attr('checked', false);
  }

  retainExistingList(e) {
    $(e.target).closest('fieldset').siblings('fieldset:hidden').each(function(i, item) {
      var selectField = $(item).find('select');
      if(selectField.attr("data-value") == this.listTarget.value){
        $(this.listTarget).closest('fieldset').remove();
        $(item).prop('hidden', false);
        selectField.val(selectField.attr("data-value"));
        $(item).find('div.modal, div.modal-backdrop').hide();
        $(item).find('input.destroy').prop('disabled', true);
      }
    }.bind(this));
  }
}
