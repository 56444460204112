import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['code', 'trigger', 'internalBundleSelector', 'container', 'schoolBundleTemplate'];


  addToSchool(e) {
    e.preventDefault();
    $('.select2-selection__rendered').html('<span class="select2-selection__placeholder">בחרו קורס להוספה</span>')
    if (!this.hasCodeTarget || this.codeTarget.value.trim().length === 0) {
      return this.addInternalBundle(e);
    } else {
      const code = this.codeTarget.value.trim();
      return this.addExternalBundle(code);
    }
  }

  addExternalBundle(code) {
    const url = this.codeTarget.dataset.url;

    $.ajax({
      url: url,
      method: 'post',
      data: { code: code },
    }).then((res) => {
      if (res.error) {
        alert(res.error);
      } else {
        this.addSchoolBundleForm(
          res.bundle_id,
          res.bundle_name,
          res.bundle_price,
          res.paid_for,
          res.bundle_share_code,
        );
        $(this.codeTarget).val('');
      }
    }).fail((err) => {
      alert(I18n.invalid_access_code);
    });
    this.codeTarget.value = '';
  }

  addInternalBundle(e) {
    e.preventDefault();
    const bundleId = this.internalBundleSelectorTarget.value;
    const option = $(`option[value="${bundleId}"]`, this.internalBundleSelectorTarget);
    const bundleName = option.text();
    const bundlePrice = option.data('price');
    const bundlePaidFor = option.data('paidfor');
    if (bundleId !== '') {
      this.addSchoolBundleForm(bundleId, bundleName, bundlePrice, bundlePaidFor);
      $(this.internalBundleSelectorTarget).val('');
    }
    $(`option[value="${bundleId}"]`, this.internalBundleSelectorTarget).attr('disabled', 'disabled')
  }

  toggleDestroyBundle(e) {
    e.preventDefault();
    const $fieldset = $(e.target).closest('fieldset');
    $fieldset.toggleClass('disabled');

    const destroyInput = $fieldset.find('input.destroy');
    if (destroyInput.is(':disabled')) {
      destroyInput.prop('disabled', false);
    } else {
      destroyInput.prop('disabled', true);
    }
  }

  removeBundleForm(e) {
    e.preventDefault();
    e.target.closest('.school_bundle_form').remove();
  }

  addSchoolBundleForm(bundleId, bundleName, bundlePrice, bundlePaidFor, bundleShareCode) {
    self = this
    const url = this.containerTarget.dataset.newItemFormUrl;
    const $container = $(this.containerTarget);

    $.get(url, {
      bundle_id: bundleId,
      bundle_sharecode: bundleShareCode,
      index: $('.school_bundle_form', this.containerTarget).length,
    }).then(function (form) {
      const item = $('fieldset.school_bundle_form', form);
      $container.append(item);
      self.toggleBundleImageAndProgressElements();
    }).fail(function (err) {
      alert(I18n.unknown_add_course_error);
      console.log(err);
    });
  }

  toggleBundleImageAndProgressElements() {
    const templateIdElement = document.querySelector('[data-target="templates--school-template.hiddenTemplateId"]');
    const addClass = (templateIdElement.value === '1');
    var toggleElements = document.querySelectorAll('.school-bundle-toggle-item');

    this.toggleElementVisibility(toggleElements, addClass);
  }

  toggleElementVisibility(elements, addClass) {
    elements.forEach(element => {
      if (addClass) {
        element.classList.add('nm-hidden');
      } else {
        element.classList.remove('nm-hidden');
      }
    });
  }
}
