import { Controller } from "stimulus";

const REQUIRED_REGISTRATION_MESHULAM_FIELDS = ['phone', 'name']
export default class extends Controller {
  static targets = ['paymentMethodHeader', 'paymentMethodContent', "dropdownButtonText", "dropdownButton", "dropdownMenu", "paymentInfoType"];

  initialize() {
    this.inlineForm = !! this.element.dataset.inline;
    this.performDefault =  !! this.element.dataset.performDefault;

    this.currentPaymentMethod = null;
  }

  disableContentsAndHeadersTargetsByName(contents, headers, name) {
    contents.forEach((el) => {
      this.activateIf(el, name);
    });

    headers.forEach((el) => {
      this.activateIf(el, name);
    });
  }

  activateIf(el, name) {
    if (el.dataset.name === name) {
      el.classList.add('tab-active');
      this.setInputsDisabled(el, false);
    } else {
      el.classList.remove('tab-active');
      this.setInputsDisabled(el, true);
    }
  }

  setInputsDisabled(el, val) {
    const inputs = el.querySelectorAll('input, select');
    for (let i=0; i < inputs.length; i++) {
      if (inputs[i].className == 'destroy' || inputs[i].className == 'import-state') { continue; }
      inputs[i].disabled = val;
    }
  }

  handleClickedPaymentMethodHeader(ev) {
    const el = ev.currentTarget;
    const name = el.dataset.name;
    const type = el.dataset.type;
    if (type === 'MeshulamPaymentInfo') {
     this.changeSignupFieldsToRequired(true);
    }
    if (name == this.currentPaymentMethod) return;

    this.currentPaymentMethod = name;
    this.showPaymentMethodForm(name, type);
    this.dropdownMenuTarget.classList.toggle('open');
    $(this.paymentInfoTypeTarget).prop('disabled', false);

    if (!this.inlineForm) {
      history.pushState({}, 'Schooler', el.href);
    }
  }

  showPaymentMethodForm(name, type) {
    this.disableContentsAndHeadersTargetsByName(this.paymentMethodContentTargets, this.paymentMethodHeaderTargets, name);
    this.changeDropdownText(name, type);
  }

  openDropdown(ev) {
    this.dropdownMenuTarget.classList.add('open');
  }

  closeDropdown(ev) {
    if (this.isChildOfDropdownButtonTarget(ev.target)) return;
    this.dropdownMenuTarget.classList.remove('open');
  }

  isChildOfDropdownButtonTarget(target) {
    for(let i=0; i<3; i++) {
      if (target === null) return false;
      if (target === this.dropdownButtonTarget) return true;

      target = target.parentElement;
    }

    return false;
  }

  changeDropdownText(name, type) {
    if (!name || !type) return;
    $(this.dropdownButtonTextTarget).html('<span class="icon icon-' + name + '" style="margin-right: 10px; right: 0; top: 0;"></span> <div style="text-align: right; padding-right: 40px">' + I18n[name] + '</div>');
    $(this.paymentInfoTypeTarget).val(type);

    $("li[data-action*=\"handleClickedPaymentMethodHeader\"]").each( function( index, element ) {
      $(this).css('background-color', $(this).attr("data-name") == name ? 'darkgrey' : '');
    });
  }

  load(e) {
    e.preventDefault();
    const href = e.currentTarget.href;
    const urlAndSelector = href + ' #new_payment_info';
    const previousActiveTab = this.element.querySelector('.tab-active');
    const newActiveTab = e.currentTarget;

    $('#new_payment_info').load(urlAndSelector, function() {
      previousActiveTab.classList.remove('tab-active');
      newActiveTab.classList.add('tab-active');
      if (!this.inlineForm) {
        history.pushState({}, 'schooler', href);
      }
    });
  }
  changeSignupFieldsToRequired(isForceCheck = false) {
    REQUIRED_REGISTRATION_MESHULAM_FIELDS.forEach(fieldName => {
      const $fieldRadioButton = $(`#${fieldName}-field-required`)
      const $mainCheckbox = $(`#registration-field-${fieldName}`)
      const $dropDown = $(`[id*="signup_field_type_${fieldName}"]`)

      if (isForceCheck || (!$mainCheckbox.is(':checked') && $dropDown.prop('selectedIndex') > 1)) {
        $mainCheckbox.prop('checked', true)
      }
      if (isForceCheck || (!$fieldRadioButton.is(':checked') && $mainCheckbox.is(':checked'))) {
        $fieldRadioButton.prop('checked', true)
        $dropDown.prop('selectedIndex', 2)//required
      }
    })
  }
  selectPaymentInfo(){
    const meshulamItem = document.querySelector('li[data-name="meshulam"]')
    if(meshulamItem){
      this.handleClickedPaymentMethodHeader({ currentTarget: meshulamItem })
      this.dropdownMenuTarget.classList.remove('open');
    }
  }
}
