import PaymentInfoController from './payment_info_controller';
import { USER_PROCESS_STATUS } from '../../services/meshulam_service';

export default class extends PaymentInfoController {
  static targets = ['policyHeader', 'paymentMethodHeader', 'policyContent', 'paymentMethodContent', 'description', 'paymentMethods', 'currentPaymentMethod', 'paymentInfoType'];

  initialize() {
    this.inlineForm = !! this.element.dataset.inline;
    this.performDefault =  !! this.element.dataset.performDefault;

    this.defaultPaymentMethod = this.element.getAttribute("data-default-payment-method");
    this.coursePaymentMethod = this.element.getAttribute("data-course-payment-method");

    this.currentPolicy = this.element.getAttribute('data-type');
    this.currentPaymentMethod = this.coursePaymentMethod || this.defaultPaymentMethod;

    this.setDescriptionAndPolicyContentTargets(this.currentPolicy);

    this.disablePaymentMethods();

    if (!this.currentPaymentMethod) {
      this.toggleMethodsOptions();
      $(this.paymentInfoTypeTarget).prop('disabled', true);
    }

    if (parseInt(this.element.dataset.isFormInvalid) > 0 || (this.element.dataset.paymentInfoInUnclosedStatus
      && USER_PROCESS_STATUS.SUCCESS !== (+this.element.dataset.paymentInfoInUnclosedStatus))) {
      this.toggleMethodsOptions();
      super.selectPaymentInfo();
    } else if (this.currentPaymentMethod === 'meshulam' && this.currentPolicy === 'paid_for') {
      super.changeSignupFieldsToRequired(true);
    } else if (this.currentPaymentMethod) {
      this.paymentMethodsTarget.style.display = 'none'
    }
  }

  // policy header

  handleClickedPolicyHeader(ev) {
    const name = ev.currentTarget.dataset.name;
    this.currentPolicy = name;
    $('#nm-row-paid-for').toggleClass('nm-hidden', name !== 'paid_for');
    $('#nm-row-expires').toggleClass('nm-hidden', name === 'free_for_all');
    $("#policy-selector-" + name)[0].checked = true;
    $('div[data-target="responder-lists-for-course.connectResponderMessage"]').html(I18n.connect_resonder_message[this.currentPolicy]);
    this.setDescriptionAndPolicyContentTargets(name);

    $(this.paymentInfoTypeTarget).prop('disabled', !$(this.paymentInfoTypeTarget).val() || name !== 'paid_for');

    this.disablePaymentMethods();
  }

  setDescriptionAndPolicyContentTargets(name) {
    this.descriptionTarget.innerHTML = I18n[name];

    this.policyContentTargets.forEach((el) => {
      super.activateIf(el, name);
    });
  }

  setPaymentMethodLinkClicked(ev) {
    this.toggleMethodsOptions();
  }

  toggleMethodsOptions() {
    $(this.currentPaymentMethodTarget).hide();
    this.currentPaymentMethod = null;

    $(this.paymentMethodsTarget).show();
    $(this.paymentInfoTypeTarget).prop('disabled', false);
  }

  disablePaymentMethods() {
    const disable_all = ( (this.currentPolicy != 'paid_for') || !($(this.paymentInfoTypeTarget).val()) )
    this.disableContentsAndHeadersTargetsByName(this.paymentMethodContentTargets, this.paymentMethodHeaderTargets, disable_all ? null : this.currentPaymentMethod);
  }
}
