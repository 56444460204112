import { Controller } from "stimulus";
import { assign, get, omit } from 'lodash';

const BASED_ON_TYPE = {
  SETTINGS_ONLY: 'settings_only',
  SETTINGS_AND_CONTENT: 'settings_and_content'
}
export default class extends Controller {

  static targets = ['dropdownMenu', 'form', 'hiddenInput'];

  initialize(){
    this.$loaderBox = $("#loaderBox");
    this.$schoolsList =$('#schools-list');

  }
  openDropdown(e) {
    if ($('ul.open').get(0) !== this.dropdownMenuTarget) {
      $('ul.open').removeClass('open')
    }
    this.dropdownMenuTarget.classList.toggle('open')
  }

  duplicateWithSettingsOnly(e) {
    e.preventDefault();
    if (!e.target.getAttribute('disabled')){
      this.sendAjaxRequest(BASED_ON_TYPE.SETTINGS_ONLY, e.target.closest('a'))
    }
  }

  duplicateWithContent(e) {
    const item = e.target;
    e.preventDefault();
    if (item.tagName == 'SPAN' || item.getAttribute('disabled') || item.parentNode.getAttribute('disabled')) {
      return;
    }
    this.sendAjaxRequest(BASED_ON_TYPE.SETTINGS_AND_CONTENT, e.target.closest('a'));
  }

  sendAjaxRequest(basedonType, container) {
    container.setAttribute('disabled', true);
    this.$loaderBox.show().find("label").html(I18n.duplicate_school)
    const self = this;

    $.ajax({
      type: "GET",
      url: this.formTarget.getAttribute('action'),
      success: function (data) {
        self.createDuplicateSchool(data, basedonType, container);
      },
      error: function (_, textStatus) {
        $("span.text").append(`<div>${textStatus}</div>`);
        $("div.notification").show()
        this.$loaderBox.toggle();
      }
    })
  }

  createDuplicateSchool(response_data, basedOnType, container) {
    this.$loaderBox.find("label").html(I18n.create_school)
    const self = this;

    $.ajax({
      type: "POST",
      url: "/schools",
      data: this.getSchoolParams(response_data, basedOnType),
      success: function (item) {
        self.$schoolsList.append(item);
        $('html,body').animate({ scrollTop: document.body.scrollHeight });
      },
      error: function (data) {
        $("span.text").html(data.responseText);
        $("div.notification").show()
      },
      complete: function () {
        container.removeAttribute('disabled');
        self.$loaderBox.toggle();
      }
    });
  }

  getSchoolParams({school, basedon, payment_info_attributes}, basedonType) {
    return {
      basedon: basedon,
      basedon_type: basedonType,
      school: assign({
        display_name: `Copy of ${school.display_name}`,
        signup_field_type_name: get(school, ['signup_fields', 'name'], 'optional'),
        signup_field_type_phone: get(school, ['signup_fields', 'phone'], 'optional'),
        payment_info_attributes
      }, omit(school, ['id', 'signup_fields', 'created_at', 'updated_at', 'name', 'preferences', 'status', 'terms', 'display_name']))
    }
  }
}
