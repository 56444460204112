import { Controller } from 'stimulus';
import { format, controlOfDisableElements } from '../../../common';
import termService from '../../../services/term_service';
import  DynamicTooltipController from "../dynamic_tooltip_controller";

const MAX_LENGTH = 100;

export default class extends DynamicTooltipController {
  static targets = ['linkAction', 'termsWrapper', 'termsHiddenInput', 'inputLink',
                    'inputPdf', 'remainingCharacters']

  initialize() {
    controlOfDisableElements(true, this.linkActionTarget)
    this.setRemainingCharacters(this.termsWrapperTarget.innerText.trim().length)
    this.termsHiddenInputTarget.value = this.termsWrapperTarget.innerHTML.trim()
    this.termsWrapperTarget.addEventListener('DOMSubtreeModified', this.termsWrapperSubtreeChanged.bind(this));
  }
  disconnect() {
    this.termsWrapperTarget.removeEventListener('DOMSubtreeModified', this.termsWrapperSubtreeChanged.bind(this));
  }

   termsWrapperSubtreeChanged(e) {
    this.termsHiddenInputTarget.value = this.termsWrapperTarget.innerHTML
    this.setRemainingCharacters(this.termsWrapperTarget.innerText.trim().length)
    !this.termsWrapperTarget.innerHTML && controlOfDisableElements(true, this.linkActionTarget)
  }

  linkActionClicked(e) {
    if(termService.CurrentSelection && termService.CurrentSelection.text) {
      controlOfDisableElements(false, this.inputLinkTarget)
    }
  }

  termsBlur(e) {
    const isAnchorSelected = termService.getSelectedAnchors(termService.getSelectedText(), this.termsWrapperTarget).length !== 0
    controlOfDisableElements(!isAnchorSelected)
  }

  termsKeypress(e) {
    const charactersLength = this.termsWrapperTarget.innerText.length
    if (e.keyCode === 13/*Enter*/ || charactersLength >= MAX_LENGTH) {
      e.preventDefault();
    }
  }
  termsPaste(e) {
    // cancel paste
    e.preventDefault();
    // get text representation of clipboard
    const text = e.clipboardData.getData('text/plain');
    // insert text manually
    if(text){
      document.execCommand('insertHTML', false, text.substr(0, MAX_LENGTH - 1));
    }
  }

  linkMouseEnter(e) {
    if (this.linkActionTarget.getAttribute('disabled')) {
        super.mouseOver(e)
        const content = e.target.querySelector('.tooltip-inner')
        content && Object.assign(content.style,{ top: '26px' })
    } else if(e.target.querySelector('.nm-tooltip')){
        e.target.removeChild(e.target.querySelector('.nm-tooltip'))
        e.target.classList.remove('nm-tooltiped')
    }
  }
  
  setRemainingCharacters(count) {
    this.remainingCharactersTarget.innerHTML = format(this.remainingCharactersTarget.dataset.format, MAX_LENGTH - count)
  }
}
