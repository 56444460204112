// src/controllers/responder_lists_controller.js
import { Controller } from "stimulus";
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['form'];

  initialize() {
    this.update = debounce(this.update, 500);
    this.previewWindowId = 'no-preview';
  }

  start(e) {
    if (this.previewWindowId === 'no-preview') {
      this.previewWindowId = `schooler-preview-${Math.floor(Math.random() * 9999999)}`;
    }
    const url = this.formTarget.dataset.courseSignupLivePreviewRootUrl;
    this.previewWindow = window.open(url, this.previewWindowId);
    this.previewWindow.onload = () => {
      this.previewWindow.onload = null;
      this.update();
    };
    e.preventDefault();
    e.stopPropagation();
  }

  connect() {
    window.addEventListener('beforeunload', this.closePreview.bind(this));
    window.addEventListener('turbolinks:before-render', this.closePreview.bind(this));
  }

  closePreview() {
    if (typeof this.previewWindow !== 'undefined') {
      this.update.cancel();
      this.previewWindow.close();
    }
  }

  update() {
    if (typeof this.previewWindow == 'undefined') return;

    const xhr = new XMLHttpRequest();

    const fd = new FormData(this.formTarget);
    // const updatePath = this.data.get("updatePath");
    const updatePath = this.formTarget.dataset.courseSignupLivePreviewUpdatePath;
    fd.append('window_id', this.previewWindowId);

    xhr.open('POST', updatePath);
    xhr.send(fd);
  }
}
