import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = ['list', 'listNewSystem', 'tagsNewSystem'];

  connect() {
    this.listsFromResponderAPI = null;
    const self = this;
    $.ajax({type: "GET", url: this.element.dataset.listUrl,
      success: (data) => {
        self.listsFromResponderAPI = data.list
        self.fillResponderLists()
      },
      error: (data, textStatus, err)=> {
        console.error(err)
      }
    })

    this.listsFromNewResponderAPI = null;
    $.ajax({type: "GET", url: this.element.dataset.listUrlNewSystem,
      success: (data) => {
        self.listsFromNewResponderAPI = data.list
        self.fillResponderListsNewSystem()
      },
      error: (data, textStatus, err)=> {
        console.error(err)
      }
    })

    this.tagsFromNewResponderAPI = null;
    $.ajax({type: "GET", url: this.element.dataset.listTagsUrl,
      success: (data) => {
        self.tagsFromNewResponderAPI = data.tags
        self.fillResponderTagsNewSystem()
      },
      error: (data, textStatus, err)=> {
        console.error(err)
      }
    })
  }

  fillResponderLists() {
    const that = this;

    this.listTargets.forEach((el, index) => {
      el.innerHTML = '';
      if (that.listsFromResponderAPI && that.listsFromResponderAPI.length > 0) {
        that.fillWithResponderListOptions(el);
      } else {
        that.fillWithWarning(el);
      }

      const value = el.dataset.value;
      $(el).val(value == null ? '' : value);
    });
  }

  fillWithResponderListOptions(el) {
    const emptyText = el.dataset.emptyText || I18n.responder_list_blank;
    const noop = createEmptyOption(emptyText);
    el.appendChild(noop);
    this.listsFromResponderAPI.forEach((item, index) => {
      const opt = createOption(item);
      el.appendChild(opt);
    });
  }

  fillWithWarning(el) {
    const noop = createEmptyOption(I18n.responder_list_missing_tokens);
    el.appendChild(noop);
  }

  fillResponderListsNewSystem() {
    const that = this;
    this.listNewSystemTargets.forEach((el, index) => {
      el.innerHTML = '';
      if (that.listsFromNewResponderAPI && that.listsFromNewResponderAPI.length > 0) {
        that.fillWithNewResponderListOptions(el);
      } else {
        that.fillWithWarning(el);
      }

      const value = el.dataset.value;
      $(el).val(value == null ? '' : value);
    });
  }

  fillWithNewResponderListOptions(el) {
    const emptyText = el.dataset.emptyText || I18n.responder_list_blank;
    const noop = createEmptyOption(emptyText);
    el.appendChild(noop);
    this.listsFromNewResponderAPI.forEach((item, index) => {
      const opt = createV2Option(item);
      el.appendChild(opt);
    });
  }

  fillResponderTagsNewSystem(){
    if(this.hasTagsNewSystemTarget && this.tagsFromNewResponderAPI && this.tagsFromNewResponderAPI.length > 0) {
      $(this.tagsNewSystemTarget).val(this.tagsFromNewResponderAPI)
    }
  }

}

function createOption(item) {
  var opt = document.createElement('option');
  opt.setAttribute('value', item.ID);
  opt.textContent = item.DESCRIPTION;
  return opt;
}

function createEmptyOption(text) {
  var opt = document.createElement('option');
  opt.setAttribute('value', '');
  opt.setAttribute('data-is-dynamic', 0);
  opt.textContent = text;
  return opt;
}

function createV2Option(item) {
  var opt = document.createElement('option');
  opt.setAttribute('value', item.id);
  opt.setAttribute('data-is-dynamic', item.is_dynamic);
  opt.textContent = item.name;
  return opt;
}
