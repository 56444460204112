import PaymentInfoController from './payment_info_controller';
import { USER_PROCESS_STATUS } from '../../services/meshulam_service';

export default class extends PaymentInfoController {
  static targets = ['paymentMethodHeader', 'paymentMethodContent', 'paymentMethods', 'currentPaymentMethod', 'paymentInfoType'];

  initialize() {
    this.inlineForm = !! this.element.dataset.inline;
    this.performDefault =  !! this.element.dataset.performDefault;

    this.defaultPaymentMethod = this.element.getAttribute("data-default-payment-method");
    this.schoolPaymentMethod = this.element.getAttribute("data-course-payment-method");

    this.currentPaymentMethod = this.schoolPaymentMethod || this.defaultPaymentMethod;

    super.showPaymentMethodForm(null, null);

    if (!this.currentPaymentMethod) {
      this.toggleMethodsOptions();
      $(this.paymentInfoTypeTarget).prop('disabled', true);
    }

    if (parseInt(this.element.dataset.isFormInvalid) > 0 || (this.element.dataset.paymentInfoInUnclosedStatus
        && USER_PROCESS_STATUS.SUCCESS !== (+this.element.dataset.paymentInfoInUnclosedStatus))) {
      this.toggleMethodsOptions();
      super.selectPaymentInfo();
    } else if (this.currentPaymentMethod === 'meshulam') {
      super.changeSignupFieldsToRequired(true);
    } else if (this.currentPaymentMethod){
      this.paymentMethodsTarget.style.display = 'none'
    }
  }

  setPaymentMethodLinkClicked(ev) {
    this.toggleMethodsOptions();
  }

  toggleMethodsOptions() {
    $(this.currentPaymentMethodTarget).hide();
    this.currentPaymentMethod = null

    $(this.paymentMethodsTarget).show();
    $(this.paymentInfoTypeTarget).prop('disabled', false);
  }
}
