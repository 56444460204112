import { Controller } from "stimulus";

const EDITOR_FIELD_KEYS = [
  'description',
  'display_header',
  'display_subheader',
  'select_course_text'
]

const ACCEPTED_FIELD_NAMES = [
  "utf8",
  "_method",
  "authenticity_token",
  "school[display_name]",
  "school[template_id]",
  "school[template_values_attributes]",
  "school[school_bundles_attributes]",
  "window_id"
]

const ACCEPTED_FIELD_NAMES_REGEX = (() => {
  const fieldNames = ACCEPTED_FIELD_NAMES
    .join('|')
    .replace(/[[\]]/g, '\\$&')

  return new RegExp(fieldNames)
})()

export default class SchoolLivePreview extends Controller {
  static targets = ['form']

  get formData () {
    let formFields = new FormData( this.formTarget )
        formFields = Object.fromEntries( formFields )

    const acceptedFields = {
      window_id: this.previewWindowId
    }

    for ( const key of Object.keys( formFields ) ) {
      if ( ACCEPTED_FIELD_NAMES_REGEX.test( key ) ) {
        acceptedFields[key] = formFields[key]
      }
    }

    return acceptedFields
  }

  initialize () {
    this.previewWindow = null
    this.previewWindowId = null

    this.ckeInstanceReady = this.ckeInstanceReady.bind( this )
    this.stop = this.stop.bind( this )
    this.updateCkeFields = this.updateCkeFields.bind( this )
    this.updateImageFields = this.updateImageFields.bind( this )

    window.UpdatePreviewWindow = this.update = this.update.bind(this);
  }

  connect () {
    window.CKEDITOR.on( 'instanceReady', this.ckeInstanceReady )

    window.addEventListener( 'beforeunload', this.stop )
    window.addEventListener( 'turbolinks:before-render', this.stop )
  }

  start ( event ) {
    event.preventDefault()
    event.stopPropagation()

    console.log( 'LIVE', 'START' )

    if ( !this.previewWindowId ) {
      this.previewWindowId = `schooler-preview-${Math.floor( Math.random() * 9 )}`
    }

    this.previewWindow = window.open(
      this.formTarget.dataset.schoolLivePreviewRootUrl,
      this.previewWindowId
    )
  }

  stop () {
    if ( this.previewWindow && !this.previewWindow.closed ) {
      this.previewWindow.close()
      this.previewWindow = null
      this.previewWindowId = null
    }
  }

  update () {
    if ( !this.previewWindow ) {
      return false
    }

    let fields = { ...this.formData }
        fields = this.updateCkeFields( fields )
        fields = this.updateImageFields( fields )

    console.log( 'LIVE', 'UPDATE !!!', fields )

    const uriParams = Object.keys( fields )
                            .map( key => `${key}=${encodeURIComponent( fields[key] )}` )
                            .join( '&' )

    const xhr = new XMLHttpRequest()
          xhr.open( 'POST', this.formTarget.dataset.schoolLivePreviewUpdatePath )
          xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' )
          xhr.send( uriParams )
  }

  updateImageFields ( fields ) {
    const CSS_URL_EXTRACT_REGEX = /^url\(['"]?([^'"]+)['"]?\)/

    for ( const fieldName in fields ) {
      if ( fields[fieldName].constructor.name === 'File' ) {
        const labelTag = document.querySelector( `[name="${fieldName}"] + label` )
        const imageBlobUrl = labelTag.style.backgroundImage.replace( CSS_URL_EXTRACT_REGEX, '$1' )

        if ( fieldName.endsWith( '[avatar_image]' ) && !imageBlobUrl.startsWith( 'blob' ) ) {
          delete fields[fieldName]
        } else {
          fields[fieldName] = imageBlobUrl
        }
      }
    }

    return fields;
  }

  ckeInstanceReady ( { editor } ) {
    if ( EDITOR_FIELD_KEYS.some( KEY => editor.name.indexOf( KEY ) !== -1 ) ) {
      editor.removeListener( 'change', this.update )
      editor.on( 'change', this.update )
    }
  }

  updateCkeFields ( fields ) {
    for ( const KEY of EDITOR_FIELD_KEYS ) {
      const textarea = document.querySelector( `textarea[id*="${KEY}"]` )
      const instanceId = textarea.id.replace( '[', '_' ).replace( ']', '' )
      const instance = window.tinymce.editors[instanceId]

      fields[textarea.name] = instance.getContent()
    }

    return fields
  }
}
