import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['radioOption', 'gaCodeSection', 'fbCodeSection', 'gaCodeField', 'fbCodeField', 'codeTextArea', 'codeTextSection',
                      'codeTextSection', 'gaInfoSection', 'fbInstructions', 'gaInstructions', 'fbEventLink', 'ga4EventLink',
                      'checkboxTextEnabled', 'checkboxTextDisabled', 'statusCheckbox', 'pixelForm', 'eventsCheckbox',
                      'fbInstructionsText', 'gaInstructionsText', 'gaLabel', 'fbLabel', 'customLabel'];

    connect() {
        this.selectedRadio = this.radioOptionTargets.filter((ele) => ele.checked)[0];
        this.toggleTextField(this.selectedRadio.value, true)
        this.toggleCheckText();
    }

    toggleCodeArea(e){
        this.toggleTextField(e.target.previousElementSibling.value);
    }

    toggleTextField(targetValue, editing){
        if(targetValue == 'ga4'){
            this.hideElements(this.codeTextAreaTarget, this.fbCodeFieldTarget, this.fbCodeSectionTarget);
            this.showElements(this.gaCodeFieldTarget, this.eventsCheckboxTarget);
            this.gaCodeSectionTarget.style.display = 'flex';
            this.eventsCheckboxTarget.style.display = 'flex';
            this.gaCodeFieldTarget.disabled = false;

            this.codeTextAreaTarget.disabled = true;
            this.fbCodeFieldTarget.disabled = true;
            if(editing === true){
                this.fbCodeFieldTarget.value = '';
                this.codeTextAreaTarget.value = '';
            }
        }else if(targetValue == 'fb_pixel'){
            this.hideElements(this.codeTextAreaTarget, this.gaCodeFieldTarget, this.gaCodeSectionTarget);
            this.showElements(this.fbCodeFieldTarget);

            this.eventsCheckboxTarget.style.display = 'flex';
            this.fbCodeSectionTarget.style.display = 'flex';

            this.fbCodeFieldTarget.disabled = false;
            this.codeTextAreaTarget.disabled = true;
            this.gaCodeFieldTarget.disabled = true;
            if(editing === true){
                this.gaCodeFieldTarget.value = '';
                this.codeTextAreaTarget.value = '';
            }
        }else{
            this.hideElements(this.eventsCheckboxTarget, this.gaCodeFieldTarget, 
                this.gaCodeSectionTarget, this.fbCodeFieldTarget, this.fbCodeSectionTarget);
            this.showElements(this.codeTextAreaTarget);
            
            this.codeTextAreaTarget.disabled = false;
            this.gaCodeFieldTarget.disabled = true;
            this.fbCodeFieldTarget.disabled = true;

            if(editing === true){
                this.gaCodeFieldTarget.value = '';
                this.fbCodeFieldTarget.value = '';
            }
        }
        this.toggleSelectAll(targetValue);
        this.toggleInfoSection(targetValue);
    }

    toggleSelectAll(targetValue){
        if(targetValue == 'ga4' || targetValue == 'fb_pixel'){
            $('#pixel_schools').multiSelect('select_all');
            $('#pixel_schools > option').each(function(){
                $(this).addClass('no-pointer');
            });
        }else{
            $('#pixel_schools > option').each(function(){
                $(this).removeClass('no-pointer');
            });
        }
        $('#pixel_schools').multiSelect('refresh');
    }

    toggleInfoSection(targetValue){
        this.hideElements(this.fbInstructionsTarget, this.gaInstructionsTarget, this.gaLabelTarget,
            this.fbLabelTarget, this.customLabelTarget, this.gaInfoSectionTarget, this.fbEventLinkTarget, 
            this.ga4EventLinkTarget);

        if(targetValue == 'ga4'){
          this.showElements(this.gaInstructionsTarget, this.gaLabelTarget);
          this.gaInfoSectionTarget.style.display = 'flex';
          this.ga4EventLinkTarget.style.display = 'inline-block';
        }else if(targetValue == 'fb_pixel'){
          this.showElements(this.fbInstructionsTarget, this.fbLabelTarget);
          this.fbEventLinkTarget.style.display = 'inline-block';
        }else{
          this.showElements(this.customLabelTarget);
        }
    }

    toggleCheckText(){
        if(this.statusCheckboxTarget.checked){
            this.showElements(this.checkboxTextEnabledTarget);
            this.hideElements(this.checkboxTextDisabledTarget);
        }else{
            this.showElements(this.checkboxTextDisabledTarget);
            this.hideElements(this.checkboxTextEnabledTarget);
        }
    }

    validateCode(e){
        this.selectedRadio = this.radioOptionTargets.filter((ele) => ele.checked)[0]

        if(this.selectedRadio.value == 'ga4') {
            if(this.gaCodeFieldTarget.value == '' || !(/^G-[0-9a-zA-Z]+$/.test(this.gaCodeFieldTarget.value))){
                this.gaInstructionsTextTarget.style.color = 'red';
                this.gaInstructionsTextTarget.innerHTML = 'הקוד לא נכון. הוא אמור להתחיל ב-G ולכלול אותיות ו/או ספרות. כדאי לקרוא את'
                e.preventDefault();  
            }else{
                this.pixelFormTarget.submit();
            }
        }else if(this.selectedRadio.value == 'fb_pixel'){
            if(this.fbCodeFieldTarget.value == '' || !(/^[0-9]+$/.test(this.fbCodeFieldTarget.value))){
                this.fbInstructionsTextTarget.style.color = 'red';
                this.fbInstructionsTextTarget.innerHTML = 'הקוד לא נכון. כדאי לוודא שהעתקת את הקוד הנכון במלואו כדאי לקרוא את '
                e.preventDefault();  
            }else{
                this.pixelFormTarget.submit();
            }
        }else{
            this.fbCodeFieldTarget.classList.remove('field-with-errors');
            this.gaCodeFieldTarget.classList.remove('field-with-errors');
            this.pixelFormTarget.submit();
        }
    }

    hideElements(...elements){
        elements.forEach(el => { el.style.display = 'none'; });
    }

    showElements(...elements){
        elements.forEach(el => { el.style.display = 'block'; });
    }
}
