import { Controller } from "stimulus";
import $ from 'jquery';

const TARGET_CLASS_NAME = 'nm-tooltiped'
const INNER_TOOTLIP_CLASS_NAME = 'tooltip-inner'
const TOOTLIP_CONTAINER_CLASS_NAME = 'nm-tooltip'

export default class extends Controller {
  mouseOver(e) {
    const target = e.currentTarget;
    const targetClassList = target.classList;
    if (!targetClassList.contains(TARGET_CLASS_NAME)) {
      targetClassList.add(TARGET_CLASS_NAME);

      const tooltipLabel = target.dataset.tooltipLabel;
      const $innerTooltip = $('<div/>')
        .addClass(INNER_TOOTLIP_CLASS_NAME)
        .append(tooltipLabel);

      $('<div/>').addClass(TOOTLIP_CONTAINER_CLASS_NAME)
        .append($innerTooltip)
        .appendTo(target)
    }
  }
}
