import ResponderListsController from './responder_lists_controller';
import $ from 'jquery';

export default class extends ResponderListsController {
  static targets = ['list', 'listsDiv', 'connectResponderMessage', 'selectContainer', ];

  connect() {
    super.connect();

    const policy = this.element.getAttribute("data-policy")
    this.connectResponderMessageTarget.innerHTML = I18n.connect_resonder_message[policy]
  }

  fillResponderLists(ev) {
    super.fillResponderLists(ev);

    var options;
    $("select.mailing-lists").each(function() {
      options = $("#responder-mailing-list > option").clone();
      $(this).append(options);
      $(this).val($(this).data().value);
    });

    const showLists = this.listsFromResponderAPI && this.listsFromResponderAPI.length >= 0;
    $(this.listsDivTarget).toggle(showLists);
    $(this.connectResponderMessageTarget).toggle(!showLists);
  }
}
