/*global I18n */
import { Controller } from "stimulus";
import { merge } from "lodash";
import $ from 'jquery';

export default class extends Controller {
  static targets = ['teacherData', 'schoolData', 'courseData', 'formData', 'errorSection',
                    'integrationAction', 'integrationSystem', 'syncExistingCheck', 'bundleBoxId'];

  connect() {
    if(this.hasTeacherDataTarget){
      this.bindTrackPackage = this.identifyAndTrack.bind(this)
      document.addEventListener("turbolinks:load", this.bindTrackPackage)
    }
    this.supportClicks = 0;
  }

  disconnect() {
    document.removeEventListener("turbolinks:load", this.bindTrackPackage)
  }

  identifyAndTrack() {
    const currentPage = this.getCurrentPage()
    console.log("currentPage:::", currentPage)

    let data = JSON.parse(this.teacherDataTarget.value)
    window.MixpanelService.identify(data.teacher_id)

    this.setSuperProperties(data, currentPage);

    this.trackPageView(currentPage)
  }

  trackPageView(currentPage) {
    if(currentPage) {
      let eventProperties = this.getEventProperties(currentPage);
      eventProperties = merge({'Page': currentPage}, eventProperties);
      window.MixpanelService.time_event('Page view', eventProperties);
    }
  }

  getEventProperties(currentPage) {
    let eventProperties = {};
    if(['Course setting'].includes(currentPage)) {
      if(this.hasCourseDataTarget) {
        let course_data = JSON.parse(this.courseDataTarget.value);
        eventProperties = {
          'Course ID': course_data.course_id,
          'Course status': course_data.course_status,
          'Course type': course_data.course_type
        }
      }
    } else if(['School setting'].includes(currentPage)){
      if(this.hasSchoolDataTarget) {
        let school_data = JSON.parse(this.schoolDataTarget.value);
        eventProperties = {
          'School ID': school_data.school_id,
          'School status': school_data.school_status
        }
      }

    }
    return eventProperties;
  }

  setCourseSuperProps(e){
    let parent = e.target.parentElement;
    const courseDataElement = this.courseDataTargets.filter((ele) => parent.contains(ele))[0];
    let course_data = JSON.parse(courseDataElement.value);
    let props = {
      'Course ID': course_data.course_id,
      'Course status': course_data.course_status,
      'Course type': course_data.course_type,
      'School ID': course_data.school_id,
    }
    window.MixpanelService.register(props)
  }

  setSchoolSuperProps(e){
    let parent = e.target.parentElement;
    const schoolDataElement = this.schoolDataTargets.filter((ele) => parent.contains(ele))[0];
    let school_data = JSON.parse(schoolDataElement.value);
    let props = {
      'School ID': school_data.school_id,
      'School status': school_data.school_status
    }
    window.MixpanelService.register(props)
  }

  setSuperProperties(data, currentPage) {
    this.unregisterSuperProperties(['Days since last login'])
    if(!['Course setting', 'New course setting', 'School setting', 'New school setting'].includes(currentPage)){
      this.unregisterSuperProperties(['Course ID', 'Course status', 'Course type', 'School ID', 'School status'])
    }
    if(['School setting', 'New school setting'].includes(currentPage)){
      this.unregisterSuperProperties(['Course ID', 'Course status', 'Course type'])
    }
    let teacherSuperProperties = {
      'Teacher status': data.teacher_status,
      '#published courses': data.published_courses,
      '#draft status courses': data.draft_status_courses,
      '#draft status schools': data.draft_status_schools,
      '#published schools': data.published_schools
    }

    if(this.hasErrorSectionTarget){
      window.MixpanelService.superIncrement('success on attempt#')
    } else {
      this.unregisterSuperProperties(['success on attempt#'])
    }

    // let schoolProperties = this.schoolSuperProperties(currentPage);
    // let courseProperties = this.courseSuperProperties(currentPage);
    // let properties = merge(schoolSuperProperties, schoolProperties, courseProperties);
    window.MixpanelService.register(teacherSuperProperties)
  }

  // schoolSuperProperties(currentPage) {
  //   let school_properties = {};
  //   if(this.hasSchoolDataTarget && ['School setting', 'New school setting'].includes(currentPage)){
  //     let school_data = JSON.parse(this.schoolDataTarget.value);
  //     school_properties = {
  //       'School ID': school_data.school_id,
  //       'School status': school_data.school_status
  //     }
  //   }
  //   return school_properties;
  // }

  // courseSuperProperties(currentPage) {
  //   let course_properties = {};
  //   if(this.hasCourseDataTarget && ['Course setting', 'New course setting'].includes(currentPage)){
  //     let course_data = JSON.parse(this.courseDataTarget.value);
  //     course_properties = {
  //       'Course ID': course_data.course_id,
  //       'Course status': course_data.course_status
  //     }
  //   }
  //   return course_properties;
  // }

  getCurrentPage() {
    const pageUrl = window.location.pathname;
    switch (pageUrl) {
      case '/courses':
        return 'Courses'
      case '/courses/new':
        return 'New course setting'
      case this.matchUrlRegex(/\/courses\/[A-Za-z0-9_-]*\/edit/, pageUrl):
        return 'Course setting'
      case '/schools':
        return 'Schools'
      case '/schools/new':
        return 'New school setting'
      case this.matchUrlRegex(/\/schools\/[A-Za-z0-9_-]*\/edit/, pageUrl):
        return 'School setting'
      case '/students':
        return 'Students'
      case '/quizzes':
        return 'Quizzes'
      case '/coupons':
        return 'Coupons'
      case '/affiliates':
        return 'Affiliates'
      case '/pixels':
        return 'Pixels'
      default:
        return null;
    }
  }

  unregisterSuperProperties(properties) {
    properties.forEach((property) => {
      window.MixpanelService.unregister(property);
    });
  }

  matchUrlRegex(regex, pageUrl){
    return pageUrl.match(regex) ? pageUrl.match(regex).input : undefined
  }

  trackEvent(){
    const currentPage = this.getCurrentPage();
    let data = {'Page': currentPage, 'Checkboxes': []};
    if(this.hasFormDataTarget){
      $(this.formDataTarget).find('[data-tracker-attr]').each((index, property) => {
        data[$(property).data('trackerAttr')] = property.value;
      });
      $(this.formDataTarget).find('[data-tracker-select]').each((index, property) => {
        data[$(property).data('trackerSelect')] = property.options[property.selectedIndex].text;
      });
      $(this.formDataTarget).find('[data-tracker-checkbox]').each((index, property) => {
        if(property.checked) data['Checkboxes'].push($(property).data('trackerCheckbox'));
      });
      $(this.formDataTarget).find('[data-tracker-radio]').each((index, property) => {
        if(property.checked) data[$(property).data('trackerRadio')] = $(property).data('trackerRadioVal');;
      });

      $(this.formDataTarget).find('[data-tracker-multiselect-count]').each((index, property) => {
        data[$(property).data('trackerMultiselectCount')] = $(property).val().length;
      });

      $(this.formDataTarget).find('[data-tracker-counter]').not(':disabled').each((index, property) => {
        let value = property.dataset.trackerCounter;
        if(!data.hasOwnProperty(value)){
          data[value] = 1;
        } else {
          data[value]++;
        }
      });
    }
    console.log('ddata::', data)
    window.MixpanelService.track(event.target.dataset.eventName, data);
  }

  supportClick(e) {
    this.supportClicks++;
    let ele = e.target;
    if (ele.tagName == 'SPAN') {
      ele = ele.parentElement;
    }
    let supportType = ele.dataset.supportType || 'More info';
    window.MixpanelService.track('Support click', {
      'Support type': supportType,
      'URL': decodeURI(ele.href),
    });
  }

  trackListAdded(e) {
    let parent = e.target.parentElement.parentElement;
    const listAction = this.integrationActionTargets.filter((action) => parent.contains(action))[0];
    const listSystem = this.integrationSystemTargets.filter((action) => parent.contains(action))[0];
    const schoolBundleId = this.bundleBoxIdTargets.filter((action) => parent.contains(action))[0];
    let props = {
      'type of integration': e.target.dataset.trackerIntegrationType,
      'integration system': listSystem.options[listSystem.options.selectedIndex].text,
      'Course ID in School' : schoolBundleId.dataset.sbId
    }

    if(listAction !== undefined) props['integration action'] = listAction.options[listAction.options.selectedIndex].text;

    const currentPage = this.getCurrentPage()
    let eventProperties = this.getEventProperties(currentPage);

    props = merge(props, eventProperties);

    if(e.target.dataset.trackerIntegrationType == 'import'){
      const listSync = this.syncExistingCheckTargets.filter((action) => parent.contains(action))[0];
      merge(props, {'sync existing subscribers - checkbox': listSync.checked})
    }
    window.MixpanelService.track('Integration with responder SAVE', props);
  }
}
