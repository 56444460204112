import { Controller } from 'stimulus'
import { format, schoolerFetch } from '../../../common'

const MAX_LENGTH = 20

const TINYMCE_SELECTORS = [
  'description',
  'display_header',
  'display_subheader',
  'select_course_text'
].map(key => `textarea[id*="${key}"]`).join(',')


export default class SchoolTemplate extends Controller {
  static targets = ['templateContainer', 'sliderContainer', 'hiddenTemplateId', 'remainingCharactersContainer', 'sliderNextArrow', 'sliderPrevArrow']

  initialize() {
    this.elementsValues = {}
    this.changeValues = {}
    this.templatesSlider = null
  }

  connect() {
    this.loadTemplatesSlider()
    this.updateRemainingCharactersCounter()
    this.toggleBundleImageAndProgressElements(this.hiddenTemplateIdTarget.value);

    this.elementsValues = this.getElementValues()
    this.createTinymceEditor(true)
  }

  loadTemplatesSlider() {
    const currentSlideIndex = this.sliderContainerTarget.dataset.selectedTemplateId - 1

    this.templatesSlider = $(this.sliderContainerTarget)
      .slick({
        accessibility: false,
        dots: true,
        draggable: false,
        infinite: false,
        nextArrow: this.sliderNextArrowTarget,
        prevArrow: this.sliderPrevArrowTarget,
        rtl: true,
        slidesToScroll: 1,
        slidesToShow: 2,

        responsive: [{
          breakpoint: 1130,
          settings: {
            slidesToShow: 1
          }
        }]
      })
      .slick('slickGoTo', currentSlideIndex, false)

  }

  templateChanged(templateId) {
    const self = this
    this.hiddenTemplateIdTarget.value = templateId

    this.updateSelectedBoxActionButton(templateId)
    this.toggleBundleImageAndProgressElements(templateId);
    var language = this.getSchoolLanguage();

    const templateParams = { template_id: templateId, entity_type: 'School', lang: language };

    schoolerFetch(this.element.dataset.templateUrl, templateParams, true, 'text')
      .then(templateAsHtml => {
        //remove old TinyMCE elements
        self.templateContainerTarget.querySelectorAll('textarea.school-template-tinymce-editor').forEach(input => {
          const editor = tinymce.editors[`${input.id}`]
          editor && editor.remove()
        })

        //insert new templates via ajax response
        self.templateContainerTarget.innerHTML = templateAsHtml

        //bind new TinyMCE elements
        self.createTinymceEditor(false)

        self.updateRemainingCharactersCounter()
        self.updateElementValues(self.changeValues)
        self.elementsValues = self.getElementValues()
      })
  }

  onSelectTemplate(e) {
    e.preventDefault()
    const changes = this.getElementValues()
    const selectedTemplateId = e.target.dataset.templateId
    Object.keys(this.elementsValues).forEach(elementName => {
      if (changes[elementName] !== this.elementsValues[elementName]) {
        this.manageAdditionalElementChanges(elementName, changes)
        this.changeValues[elementName] = changes[elementName]
      }
    })
    this.templateChanged(selectedTemplateId)
  }

  manageAdditionalElementChanges(elementName, changes) {
    if (elementName === 'school[template_values_attributes][0][logo]') {
      this.changeValues['logo'] = changes['logo']
    } else if (elementName === 'school[template_values_attributes][1][cover_image]') {
      this.changeValues['cover_image'] = changes['cover_image']
    }
  }

  onInputChanged(e) {
    const value = e.target.value.trim().length
    if (value <= MAX_LENGTH) {
      this.setRemainingCharacters(e.target.value.trim().length)
    } else {
      e.target.value = e.target.value.substring(0, MAX_LENGTH)
    }
  }

  onChangeTemplateAfterConfirmation(e) {
    this.templateChanged(e.detail.selectedTemplateId)
  }

  updateRemainingCharactersCounter() {
    //update text characters length
    if (this.hasRemainingCharactersContainerTarget) {
      const input = this.remainingCharactersContainerTarget.closest('.nm-form-row').querySelector('input')
      input && this.setRemainingCharacters(input.value.trim().length)
    }
  }

  setRemainingCharacters(count) {
    this.remainingCharactersContainerTarget.innerHTML = format(this.remainingCharactersContainerTarget.dataset.format, MAX_LENGTH - count)
  }

  selectTemplate(templateCarousel) {
    const { selectedTemplateId } = this.carouselContainerTarget.dataset

    if (selectedTemplateId) {
      //find template index in carousel
      const selectedTemplateIndex = templateCarousel.slides
        .findIndex(slide => slide.$el.dataset.templateId === selectedTemplateId)
      //find page index in carousel
      const pageIndex = templateCarousel.pages.findIndex(page => page.indexes.includes(selectedTemplateIndex))
      if (templateCarousel.pageIndex !== pageIndex) {
        templateCarousel.slideTo(pageIndex + 1)
      }
    }
  }

  getElementValues() {
    const changes = {}
    const templateId = this.hiddenTemplateIdTarget.value;
    //get elements values
    [...this.templateContainerTarget.querySelectorAll(`[data-template-id="${templateId}"][id*=logo],
       [data-template-id="${templateId}"][id*=cover_image],
       [type="color"], [type="text"], [type="checkbox"], ${TINYMCE_SELECTORS}`)]
      .forEach(element => {
        if (element.type === 'file' && element.nextElementSibling.style.backgroundImage) {
          changes[element.name] = element.nextElementSibling.style.backgroundImage
          changes[element.dataset.fieldName] = document.getElementById(`school_template_${element.dataset.fieldName}`)
        } else if (element.type === 'color' || element.type === 'text') {
          changes[element.name] = element.value
        } else if (element.type === 'checkbox') {
          changes[element.name] = element.checked ? '1' : '0'
        } else if (element.nodeName === 'TEXTAREA') {
          const instanceName = element.id.replace('[', '_').replace(']', '')
          const instanceNameAsList = instanceName.split('_')
          const objName = instanceNameAsList.length > 2 ? `${instanceNameAsList[instanceNameAsList.length - 2]}_${instanceNameAsList[instanceNameAsList.length - 1]}` : `${instanceNameAsList[instanceNameAsList.length - 1]}`

          tinymce.editors[instanceName] && (changes[objName] = tinymce.editors[instanceName].getContent().replace('\n', ''))
        }
      });
    return changes;
  }

  updateSelectedBoxActionButton(templateId) {
    let selectedBoxFooter = document.querySelector(`.templates-slider--slide[data-is-selected-template="1"] .templates-slider--footer`)
    if(selectedBoxFooter == null) {
      selectedBoxFooter = document.querySelector('.templates-slider--slide-selected .templates-slider--footer')
    }
    const selectedTemplateLabelContainer = selectedBoxFooter.querySelector('.templates-slider--active')
    if (selectedBoxFooter && !selectedTemplateLabelContainer.classList.contains('nm-hidden')) {
      selectedTemplateLabelContainer.classList.add('nm-hidden')
      selectedBoxFooter.querySelector('.nm-btn').classList.remove('nm-hidden')
      selectedBoxFooter.parentElement.classList.remove('templates-slider--slide-selected')
      selectedBoxFooter.parentElement.dataset.isSelectedTemplate = 0
    }

    const boxFooterForSelect = document.querySelector(`.templates-slider--footer [data-template-id="${templateId}"]`).parentElement
    const templateLabelContainer = boxFooterForSelect.querySelector('.templates-slider--active')
    if (boxFooterForSelect && templateLabelContainer.classList.contains('nm-hidden')) {
      templateLabelContainer.classList.remove('nm-hidden')
      boxFooterForSelect.querySelector('.nm-btn').classList.add('nm-hidden')
      boxFooterForSelect.parentElement.classList.add('templates-slider--slide-selected')
      boxFooterForSelect.parentElement.dataset.isSelectedTemplate = 1
    }
  }

  updateElementValues(changeValues) {
    const changes = {}
    const templateId = this.hiddenTemplateIdTarget.value;

    if (Object.keys(changeValues).length) {
      [...this.templateContainerTarget.querySelectorAll(`[data-template-id="${templateId}"][id*=logo],
        [data-template-id="${templateId}"][id*=cover_image],
        [type="color"], [type="text"], [type="checkbox"], ${TINYMCE_SELECTORS}`)]
        .forEach(element => {
          if (element.type === 'file' && element.nextElementSibling.style.backgroundImage
            && changeValues[element.name] && changeValues[element.name] !== element.nextElementSibling.style.backgroundImage) {
            element.nextElementSibling.style.backgroundImage = changeValues[element.name]

            // Replace element to persist the image.
            var newElement = document.getElementById(`school_template_${element.dataset.fieldName}`)
            newElement.replaceWith(changeValues[element.dataset.fieldName])
          } else if ((element.type === 'color' || element.type === 'text')
            && changeValues[element.name] && element.value !== changeValues[element.name]) {
            element.value = changeValues[element.name]
          } else if (element.type === 'checkbox' && changeValues[element.name] && element.value !== changeValues[element.name]) {
            element.checked = changeValues[element.name] === '1'
          } else if (element.nodeName === 'TEXTAREA') {
            const instanceName = element.id.replace('[', '_').replace(']', '')
            const instanceNameAsList = instanceName.split('_')
            const objName = instanceNameAsList.length > 2 ? `${instanceNameAsList[instanceNameAsList.length - 2]}_${instanceNameAsList[instanceNameAsList.length - 1]}` : `${instanceNameAsList[instanceNameAsList.length - 1]}`
            if (tinymce.editors[instanceName] && changeValues[objName]
              && changeValues[objName] !== tinymce.editors[instanceName].getContent().replace('\n', '')) {
              tinymce.editors[instanceName].on('init', function () {
                tinymce.editors[instanceName].setContent(changeValues[objName]);
              });
            }
          }
        });
    }

    return changes;
  }

  toggleBundleImageAndProgressElements(templateId) {
    const toggleElements = document.querySelectorAll('.school-bundle-toggle-item');
    const addClass = (templateId === '1');

    this.toggleElementVisibility(toggleElements, addClass);
  }

  toggleElementVisibility(elements, addClass) {
    elements.forEach(element => {
      if (addClass) {
        element.classList.add('nm-hidden');
      } else {
        element.classList.remove('nm-hidden');
      }
    });
  }

  createTinymceEditor(setElementValues) {
    const self = this

    self.templateContainerTarget.querySelectorAll('textarea.school-template-tinymce-editor').forEach(input => {
      try {
        var tinymceOptions = JSON.parse(input.getAttribute('data-tinymce-options'));

        tinymceOptions.content_style = "body { font-family: 'Assistant', sans-serif; }";

        tinymce.init({
          selector: '#' + input.id,
          language: 'he_IL',
          language_url: '/he_IL.js',
          plugins: tinymceOptions.plugins,
          toolbar: tinymceOptions.toolbar,
          menubar: tinymceOptions.menubar,
          removeDialogTabs: tinymceOptions.removeDialogTabs,
          font_formats: tinymceOptions.font_formats,
          fontsize_formats: tinymceOptions.fontsize_formats,
          textcolor_map: tinymceOptions.textcolor_map,
          directionality: tinymceOptions.directionality,
          color_picker_callback: function(callback, value, meta) {
            if (meta === 'backgroundcolor') {
              tinymce.activeEditor.execCommand('mceColorPicker', false, value);
            } else if (meta === 'forecolor') {
              tinymce.activeEditor.execCommand('mceColorPicker', false, value);
            }
          },
          content_style: tinymceOptions.content_style,
          setup: function(editor) {
            editor.addMenuItem('customalignleft', {
              text: 'Align Left',
              icon: 'align-left',
              onclick: function() {
                editor.execCommand('JustifyLeft');
              }
            });

            editor.addMenuItem('customaligncenter', {
              text: 'Align Center',
              icon: 'align-center',
              onclick: function() {
                editor.execCommand('JustifyCenter');
              }
            });

            editor.addMenuItem('customalignright', {
              text: 'Align Right',
              icon: 'align-right',
              onclick: function() {
                editor.execCommand('JustifyRight');
              }
            });

            editor.addMenuItem('customalignjustify', {
              text: 'Justify',
              icon: 'align-justify',
              onclick: function() {
                editor.execCommand('JustifyFull');
              }
            });

            editor.on("init", function () {
              if (setElementValues) {
                const content = editor.getContent();
                const instanceName = editor.id.replace("[", "_").replace("]", "");
                const instanceNameAsList = instanceName.split("_");
                const objName =
                  instanceNameAsList.length > 2
                    ? `${instanceNameAsList[instanceNameAsList.length - 2]}_${
                        instanceNameAsList[instanceNameAsList.length - 1]
                      }`
                    : `${instanceNameAsList[instanceNameAsList.length - 1]}`;

                self.elementsValues[objName] = content;
              }
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    })
  }

  getSchoolLanguage() {
    var selectElement = document.getElementById('school_teacher_translation_attributes_translation_id');
    var selectedIndex = selectElement.selectedIndex;
    var selectedOption = selectElement.options[selectedIndex];
    switch(selectedOption.value) {
      case '1':
        return 'Hebrew'
      case '2':
        return 'English'
    }
  }
}
