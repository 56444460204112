import { Controller } from "stimulus"
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = [ "tagify", "tagNames" ]

  connect() {
    const whitelist = $("#v2-tags").val().split(','); //this.element.getAttribute('data-whitelist').split(',')
    this.tagify = new Tagify(this.tagifyTarget, {
     whitelist: whitelist,
     maxTags: 10,
     editTags : false, 
     dropdown: {
        maxItems: 20,           // <- mixumum allowed rendered suggestions
        classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
        enabled: 0,             // <- show suggestions on focus
        closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
      },
    })
    this.tagify.on('add', e => this.saveTagNames(e.detail.tagify))
    this.tagify.on('remove', e => this.saveTagNames(e.detail.tagify))
    this.tagify.on('focus', e => this.updateTags(e.detail.tagify))

    if(this.hasTagNamesTarget){
      const tagNamesStr = this.tagNamesTarget.value
      if (tagNamesStr.length > 0) {
       this.tagify.addTags(tagNamesStr.split(','))
      }
    }
  }

  saveTagNames(tagify) {
   this.tagNamesTarget.value = tagify.value.map(v => v.value)
  }
  
  updateTags(tagify) {
   tagify.whitelist = $("#v2-tags").val().split(',')
  }

  disconnect() {
    this.tagify.removeAllTags()
    const classes = this.element.getElementsByClassName('tagify')
    Array.from(classes).forEach(e => e.remove())
  }

}