/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// src/application.js
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import jQuery from 'jquery';
import 'jquery.scrollbar';
import * as ActiveStorage from "@rails/activestorage";
import renderReactPlayer from "react-player/dist/ReactPlayer.standalone.es6";

ActiveStorage.start()

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

window.renderReactPlayer = renderReactPlayer;

jQuery(function () {
  jQuery.ajaxSetup({
    headers: { 'X-CSRF-Token': jQuery('meta[name="csrf-token"]').attr('content') },
  });
}
);

jQuery(document).on('turbolinks:load', function () {
  jQuery('.aside-menu').scrollbar();
  const $scrollContent = $('.scroll-content')
  $(window).on('resize', () => $scrollContent.css('max-height', `${window.innerHeight - 83}px`))

  $('div').filter(function () {
    return $(this).css('left') == '-1000px' &&
      $(this).css('top') == '-1000px' &&
      $(this).css('overflow') == 'scroll' &&
      $(this).css('position') == 'absolute' &&
      $(this).css('width') == '200px';
  }).remove();
});
