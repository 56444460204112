import { Controller } from 'stimulus';
import { copyTextToClipboard } from '../../services/copy_text_to_clipboard'

export default class extends Controller {
  static targets = ['inputText'];

  get textToCopy() {
    return this.inputTextTarget.href || this.inputTextTarget.value || "";
  }

  get notificationText() {
    return this.element.dataset?.notificationText || I18n.copy_ok || '';
  }

  copy(event) {
    event.preventDefault();

    const copiedSuccessfully = copyTextToClipboard(this.textToCopy);
    if (copiedSuccessfully) {
      this.showNotification();
    }
  }

  showNotification() {
    $('.notifyjs-wrapper').trigger('notify-hide');
    $(this.element).notify(this.notificationText, 'success');
  }
}
