import { Controller } from 'stimulus';

import { DirectUpload } from "@rails/activestorage"
import {DISABLE_CLASS } from '../../../common';

const DIRECT_UPLOAD_CLASS ={
  PROGRESS: 'direct-upload__progress',
  COMPLETE: 'direct-upload__complete',
  ERROR: 'direct-upload--error'
}
const UPLOAD_CLASS ={
  PROGRESS: 'progress',
  COMPLETE: 'complete',
  ERROR: 'error'
}
const HIDING_PROGRESS_TIMEOUT = 1000;
const HIGHLIGHT_DRAG_AREA_CLASS_NAME = 'highlight-drag-area';
export default class ActiveStorageUploaderController extends Controller {
  static targets = ['modalOptionLink', 'modalOptionLinkBackdrop',  'saveButton', 'directUploadProgressContainer',
                    'directUploadProgressWrapper', 'inputPdf', 'labelContainer']
  get UploadClasses(){
    return DIRECT_UPLOAD_CLASS
  }
  initialize(){
    this.preview = null;
  }
  onDrop(e) {
    e.preventDefault()
    const files = e.dataTransfer.files;
    if(!this.inputPdfTarget.disabled && files.length && files[0].type === 'application/pdf'){
       this.uploadFile(e)
    }
    this.directUploadProgressWrapperTarget.classList.remove(HIGHLIGHT_DRAG_AREA_CLASS_NAME)
  }
  onDragOver(e) {
    e.preventDefault();
    if (!this.inputPdfTarget.disabled) {
      this.directUploadProgressWrapperTarget.classList.add(HIGHLIGHT_DRAG_AREA_CLASS_NAME)
    }
  }

  onDragLeave(e) {
    if (!this.inputPdfTarget.disabled) {
      this.directUploadProgressWrapperTarget.classList.remove(HIGHLIGHT_DRAG_AREA_CLASS_NAME)
    }
  }

  onDragEnter(e) {
    if (!this.inputPdfTarget.disabled) {
    this.directUploadProgressWrapperTarget.classList.add(HIGHLIGHT_DRAG_AREA_CLASS_NAME)
    }
  }

  uploadFile(e) {
    // your form needs the file_field direct_upload: true, which
    // provides data-direct-upload-url
    const {dataset, name} = this.inputPdfTarget
    if(!dataset.directUploadUrl){
      throw Error(`the directUploadUrl doesn't initialized`)
    }
    const file =  e instanceof DragEvent ? e.dataTransfer.files[0] : this.inputPdfTarget.files[0]
    const upload = new DirectUpload(file, dataset.directUploadUrl, this)
    const self = this;
    this.directUploadProgressContainerTarget.classList.remove(DIRECT_UPLOAD_CLASS.ERROR, DIRECT_UPLOAD_CLASS.COMPLETE)
    this.inputPdfTarget.classList.remove(UPLOAD_CLASS.ERROR, UPLOAD_CLASS.COMPLETE)
    this.labelContainerTarget.dataset.label = this.labelContainerTarget.dataset.complete
    this.directUploadProgressContainerTarget.innerText = ''
    this.directUploadProgressContainerTarget.title = ''
    //upload file to server and store at active_storage_blobs table without link to model
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.error(`Can not uploaded the file: ${error}`)
        this.directUploadProgressContainerTarget.classList.add(DIRECT_UPLOAD_CLASS.ERROR)
        this.inputPdfTarget.classList.remove(UPLOAD_CLASS.ERROR)
      } else {
        // Add an appropriately-named hidden input to the form with a
        // value of blob.signed_id so that the blob ids will be
        // transmitted in the normal upload flow
        if (blob) {
          fetch(`/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`)
          .then(response => {
              self.preview = {blobId: blob.id, filename: blob.filename}
              this.updateFileName(blob.filename)
              this.inputPdfTarget.dispatchEvent(new CustomEvent('fileUploaded', { bubbles: true, detail: self.preview }))
              self.saveButtonTarget.classList.remove(DISABLE_CLASS)
              const hiddenField = document.createElement('input')
              Object.assign(hiddenField, { type: 'hidden', value: blob.signed_id, name })
              hiddenField.dataset.blobId = blob.id
              document.forms[0].appendChild(hiddenField)
            })
            .catch(err => {
              self.saveButtonTarget.classList.add(DISABLE_CLASS)
              self.preview = null
              console.error(`Can not get the url for file:${blob.filename} with error: ${err}`)
            })
        } else {
          console.error('Can not get blob')
        }
        // you might clear the selected files from the input
        this.inputPdfTarget.value = null
      }
    })
  }
  directUploadWillStoreFileWithXHR(request) {
    this.directUploadProgressContainerTarget.style.width = ''
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event))
  }
  directUploadDidProgress(event) {
    const percent = parseInt((event.loaded / event.total) * 100)
    this.directUploadProgressContainerTarget.classList.add(DIRECT_UPLOAD_CLASS.PROGRESS)
    this.inputPdfTarget.classList.add(UPLOAD_CLASS.PROGRESS)
    this.directUploadProgressContainerTarget.style.width = `${percent}%`

    if (percent >= 100) {
      setTimeout(function()  {
        if(this.preview && this.preview.filename){
          this.updateFileName(this.preview.filename)
        }
      }.bind(this), HIDING_PROGRESS_TIMEOUT)
    }
  }
  updateFileName(filename){
      this.directUploadProgressContainerTarget.style.width = ''
      this.directUploadProgressContainerTarget.classList.replace(DIRECT_UPLOAD_CLASS.PROGRESS, DIRECT_UPLOAD_CLASS.COMPLETE)
      this.inputPdfTarget.classList.replace(UPLOAD_CLASS.PROGRESS, UPLOAD_CLASS.COMPLETE)
     if(filename){
        this.directUploadProgressContainerTarget.innerText = filename
        this.directUploadProgressContainerTarget.title = filename
      }
  }
}
