import { invert } from 'lodash'

const USER_PROCESS_STATUS = {
    PENDING: 0,
    SUCCESS: 1,
    ERROR: 2,
    DELETED: 3
}
const INVERT_USER_PROCESS_STATUS = invert(USER_PROCESS_STATUS)
const CUSTOM_EVENT_NAMES = {
    INFO_DIALOG: 'infoDialog',
    WARNING_DIALOG: 'warningDialog',
    DATA_RECEIVED: 'meshulamDataReceived',
    APPROVED_DELETE: 'approvedDeleteBusinessId',
    PENDING_TO_RESPONSE: 'pendingToResponse'
}

class MeshulamService {
    getTopStripType(status) {
        let stripType = 'danger';
        if (status === USER_PROCESS_STATUS.PENDING) {
            stripType = 'info'
        } else if (status === USER_PROCESS_STATUS.SUCCESS) {
            stripType = 'success'
        }
        return stripType
    }

    createOrUpdateTopStatusStrip({ status, message, isDisplayStrip },
        errorMessageContainerTarget, notificationWrapper, notificationScriptTemplate) {
        let stripType = this.getTopStripType(status)

        errorMessageContainerTarget.style.display = status === USER_PROCESS_STATUS.ERROR ? '' : 'none'

        let notificationContainer = notificationWrapper.querySelector('#clientNotification')

       if(isDisplayStrip) {
            if (notificationContainer) {
                notificationContainer.className = `my-notification my-notification-${stripType}`
                const icon = notificationContainer.querySelector('.icon')
                icon.className = `icon icon-${stripType}`
                const stripMessage = notificationContainer.querySelector('.text')
                stripMessage.innerText = message || this.getMessageByStatus(status)
            }
            else {
                // Compile the template data into a function
                const compiledTemplate = Handlebars.compile(notificationScriptTemplate)
                //add values inside template
                const notificationAsString = compiledTemplate({ status: stripType, message: message || this.getMessageByStatus(status)})
                // Insert the HTML code into the page
                notificationWrapper.insertAdjacentHTML('afterbegin', notificationAsString)
            }
       } else if (notificationContainer){
            notificationWrapper.removeChild(notificationContainer)
       }
    }

    getMessageByStatus(status){
        const statusAsString = INVERT_USER_PROCESS_STATUS[status].toLocaleLowerCase()
        const message =  window.I18n.meshulam_data.status_messages[statusAsString]
        return message
    }

    updatePaymentTitle(container, businessId, status) {
        const statusAsNumber = Number(status)
        let title;
        if (status && (statusAsNumber === USER_PROCESS_STATUS.PENDING || statusAsNumber === USER_PROCESS_STATUS.ERROR)) {
            title = '&nbsp;'
        } else {
            const { withNoData, withData } = container.dataset
            const isSelectedAddNewOption = businessId === '-1'
            title = isSelectedAddNewOption || isNaN(businessId) ? withNoData : withData
        }
        Object.assign(container, { innerHTML: title})
    }
}

const meshulamService = new MeshulamService()
export {
    meshulamService,
    USER_PROCESS_STATUS,
    INVERT_USER_PROCESS_STATUS,
    CUSTOM_EVENT_NAMES
}

