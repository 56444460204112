import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['component'];

  initialize() {
    this.defaultDisplay = window.getComputedStyle(this.componentTarget).display;
    if (this.element.dataset.init === 'on') {
      this.show();
    } else {
      this.hide();
    }
  }

  toggle(ev) {
    if (ev.target.checked) {
      this.disableRequiredField();
      this.show();
    } else {
      this.enableRequiredField();
      this.hide();
    }
  }

  show() {
    this.componentTarget.style.display = this.defaultDisplay;
  }

  hide() {
    this.componentTarget.style.display = 'none';
    this.componentTarget.querySelectorAll('input').forEach(el => { el.disabled = true; });
  }

  disableRequiredField() {
    let isRequiredFields = document.querySelectorAll('.quiz--is-required');

    for (let i = 0; i < isRequiredFields.length; i++) {
      let checkbox = isRequiredFields[i].querySelector('input[type="checkbox"]');
      checkbox.checked = true;
      checkbox.disabled = true;

      isRequiredFields[i].setAttribute('data-toggle', 'tooltip');
      isRequiredFields[i].setAttribute('data-original-title', 'במבחן חוסם כל השאלות הן חובה');
      isRequiredFields[i].setAttribute('data-placement', 'right');
      isRequiredFields[i].style.opacity = '50%';

      $(checkbox).trigger('change');
    }
    $('[data-toggle="tooltip"]').tooltip();
  }

  enableRequiredField(){
    let isRequiredFields = document.querySelectorAll('.quiz--is-required');

    for (let i = 0; i < isRequiredFields.length; i++) {
      let checkbox = isRequiredFields[i].querySelector('input[type="checkbox"]');
      checkbox.disabled = false;
      isRequiredFields[i].style.opacity = '100%';

      if (isRequiredFields[i].hasAttribute('data-toggle')) {
        isRequiredFields[i].removeAttribute('data-toggle');
      }
      if (isRequiredFields[i].hasAttribute('data-original-title')) {
        isRequiredFields[i].removeAttribute('data-original-title');
      }
      if (isRequiredFields[i].hasAttribute('data-placement')) {
        isRequiredFields[i].removeAttribute('data-placement');
      }
    }
  }
}
