import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["requiredCheckBox", "requiredCheckBoxDiv" , "mainCheckBox", "selector", "requiredText"]

  initialize() {
    if (this.selectorTarget.selectedIndex == 0) {
      this.mainCheckBoxTarget.checked = true;
      this.requiredCheckBoxTarget.checked = false;
      this.requiredTextTarget.innerHTML = I18n.optional_field;
      this.show();
    }
    else if (this.selectorTarget.selectedIndex == 2) {
      this.mainCheckBoxTarget.checked = true;
      this.requiredCheckBoxTarget.checked = true;
      this.requiredTextTarget.innerHTML = I18n.required_field;
      this.show();
    } else {
      this.requiredTextTarget.innerHTML = this.requiredCheckBoxTarget.checked ? I18n.required_field : I18n.optional_field;
    }
  }

  toggle(ev) {
    ev.target.checked ? this.show() : this.hide() ;
  }

  toggleRequired(ev) {
    this.selectorTarget.selectedIndex = ev.target.checked ? 2 : 0;
    this.requiredTextTarget.innerHTML = ev.target.checked ? I18n.required_field : I18n.optional_field;
  }

  // optional = 0 ; hidden = 1 ; required = 2 ;
  show() {
    this.requiredCheckBoxDivTarget.style.display = 'inline-flex';
    this.selectorTarget.selectedIndex = this.requiredCheckBoxTarget.checked ? 2 : 0 ;
  }

  hide() {
    this.requiredCheckBoxDivTarget.style.display = 'none';
    this.selectorTarget.selectedIndex = 1;
  }

  preventClick(ev) {
    ev.preventDefault();
  }
}
