import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["listsConfirmationModal", "modalInactiveBackdrop"] 

  generateNewToken(e) {
    e.preventDefault();

    if(this.element.dataset.listsExist === 'true'){
      this.showConfirmation();
    }else{
      this.callGenerateToken(e);
    }
  }

  callGenerateToken(e, updateReponder=false){
    const changeUrlDiv = this.changeUrlDiv;
    const button = e.target;
    $(button).attr("value", I18n.creates_new_quickaccess_url)

    var url = this.element.getAttribute('data-generate-token-url');
    if(updateReponder === true){
      url = url + `?update_reponder_users=true`
    }
    $.ajax({
      type: "GET",
      url: url,
      contentType: "application/json",
      dataType: "json",
      success: function(data) {
        changeUrlDiv(data);
      },
      error: function(data, textStatus, err) {
        alert(data.responseJSON.err);
      },
      complete: function() {
        $(button).attr("value", I18n.create_new_quickaccess_url)
      }
    })
  }

  changeUrlDiv(data) {
    $("#quickaccess-url").attr("href", data.url);
    $("#quickaccess-url").html(data.url);
  }

  showConfirmation(){
    $(this.listsConfirmationModalTarget).addClass('show').show();
    $(this.modalInactiveBackdropTarget).show();
  }

  hideModal(e) {
    $(this.listsConfirmationModalTarget).add(this.modalInactiveBackdropTarget).hide();
  }

  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.hideModal(e);
  }

  updateResponderUsers(e){
    this.callGenerateToken(e, true);
    this.hideModal(e);
  }

  updateLinkOnly(e){
    this.callGenerateToken(e, false);
    this.hideModal(e);
  }
}
