import { Controller } from 'stimulus';
import $ from 'jquery';
import _ from 'lodash';
import { dispatchCustomEvent } from '../../../common';

export default class TemplateInfoDialogController extends Controller {
    static targets = ['modalOption', 'saveButton']

    initialize() {
        this.$modalOption = $(this.modalOptionTarget)
        this.$modalOptionBackdrop = $('.template-backdrop')
    }

    showModal(e) {
        this.$modalOption
            .data('selectedTemplateId', e.detail.selectedTemplateId)
            .addClass('show')
            .add(this.$modalOptionBackdrop)
            .show();

    }

    hideModal(e) {
        this.$modalOption.removeClass('show')
            .add(this.$modalOptionBackdrop)
            .hide();
    }

    outsideClick(e) {
        if (e.target === e.currentTarget)
            this.hideModal(e);
    }
    //click on continue to selected template
    ignoreChanges(){
      this.hideModal()
      dispatchCustomEvent('continueChangeTemplate',
      { selectedTemplateId: this.$modalOption.data('selectedTemplateId') })
    }
}
