import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = ['list', 'listType', 'addTagsLink', 'addTagsSection', 'editSection', 'tagNames', 'ruleName',
                    'listView', 'treeList', 'toggleEdit', 'tagsText', 'listText', 'ruleNameText', 'systemText',
                    'errMsg', 'importAction', 'inactiveWarningModal', 'modalInactiveBackdrop', 'importActionText'];

  connect(){
    this.isEditing = false;
    if(this.hasListTarget && !this.listTarget.dataset.value){
      this.fillSelectList();
    }
  }

  showEditSection(e){
    e.preventDefault();
    this.isEditing = true;
    this.listViewTarget.classList.add('hidden-important');
    this.editSectionTarget.hidden = false;

    if(this.listTarget.value.length == 0){
      this.fillSelectList(e);
      this.listTarget.value = this.listTarget.dataset.value;
    }
  }

  fillSelectList(e){
    if(this.listTypeTarget.value == ''){
      return;
    }
    var options = [];
    if(this.listTypeTarget.value == "ImportSource::RavMeser::V1" || this.listTypeTarget.value == "ExportDestination::RavMeser::V1"){
      options = $("#responder-mailing-list > option").clone();
      if(this.hasAddTagsLinkTarget){
        $(this.addTagsLinkTarget).hide();
        $(this.addTagsSectionTarget).hide();
      }
      if(this.hasAddTagsSectionTarget){
        $(this.treeListTarget).after($(this.addTagsSectionTarget));
        $(this.addTagsSectionTarget).hide();
      }
    }else{
      if(this.listTypeTarget.value == "ImportSource::RavMeser::V2"){
        options = $("#responder-mailing-list-v2 > option").clone();
      }else if(this.listTypeTarget.value == "ExportDestination::RavMeser::V2"){
        options = $("#responder-mailing-list-v2 [data-is-dynamic=0]").clone();
      }
      if(this.hasAddTagsLinkTarget && $(this.addTagsSectionTarget).is(":hidden")){
        $(this.addTagsLinkTarget).show();
      }
    }
    options = this.filteredOptions(options);
    $(this.listTarget).html(options);
  }

  filteredOptions(options){
    var selectedLists = [];
    if(["ImportSource::RavMeser::V1", "ImportSource::RavMeser::V2"].includes(this.listTypeTarget.value) ){
      selectedLists = $(this.element.parentElement).find('.list-display').not('.deleted').map( function(i, item) {
        return $(item).find('#import_list').data('value')
      });
    }else{
      selectedLists = $(this.element.parentElement).find('.list-display').not('.deleted').map( function(i, item) {
        if(this.hasRuleNameTarget && $(item).find("#export_rule_id").data('value') == this.ruleNameTarget.value){
          return $(item).find("#export_list").data('value')
        }
      }.bind(this));
    }

    var inactiveLists = [];
    if(this.data.get('importableTypeValue') == 'School'){
      inactiveLists = $('.list-display.list-import-section').not('.deleted').map( function(i, item) {
        if($($(item).find('#import_action'))[0].dataset.value != 'create_user'){
          return $(item).find('#import_list').data('value')
        }
      })
    }

    selectedLists = $.merge(selectedLists, inactiveLists);
    var data = options.map(function(index, item) {
      if($.inArray(parseInt(item.value), selectedLists) > -1){
          item.disabled = true;
      }
      return item;
    })
    return data;
  }

  enableAddTags(){
    $(this.addTagsLinkTarget).hide();
    $(this.addTagsSectionTarget).appendTo($(this.treeListTarget))
    $(this.addTagsSectionTarget).show();
  }

  confirmImportType(e){
    if(this.hasImportActionTarget && this.importActionTarget.value == 'inactivate_user'){
      e.preventDefault();
      $(this.inactiveWarningModalTarget).addClass('show').show();
      $(this.modalInactiveBackdropTarget).show();
    }else{
      this.toggleEditView(e);
    }
  }

  hideModal(e) {
    $(this.inactiveWarningModalTarget).add(this.modalInactiveBackdropTarget).hide();
  }

  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.hideModal(e);
  }

  confirmInactiveModel(e) {
    this.toggleEditView(e);
    this.hideModal(e);
  }

  toggleEditView(e) {
    e.preventDefault();
    this.isEditing = false;
    this.errMsgTarget.hidden = true;
    if(this.listTarget.options.selectedIndex < 1){
      this.errMsgTarget.innerText = 'כל שדות הבחירה הם חובה'
      this.errMsgTarget.hidden = false;
      return false;
    }
    this.listViewTarget.classList.remove('hidden-important');
    this.editSectionTarget.hidden = true;

    if(this.hasTagNamesTarget){
      if(this.tagNamesTarget.value && this.listTypeTarget.value == "ExportDestination::RavMeser::V2"){
        $(this.tagsTextTarget).html("<img src='/images/arrow-left.svg' class='icon-arrow-left-3'/>" + this.tagNamesTarget.value)
      }else{
        $(this.tagsTextTarget).html('');
      }
    }
    var selectedList = this.listTarget.options[this.listTarget.options.selectedIndex];
    $(this.listTextTarget).html(selectedList.text);
    if(["ExportDestination::RavMeser::V1", "ExportDestination::RavMeser::V2"].includes(this.listTypeTarget.value) ){
      var selectedRuleId = this.ruleNameTarget.options[this.ruleNameTarget.options.selectedIndex];
      $(this.listViewTarget).find('#export_rule_id').attr('data-value', selectedRuleId.value);
      $(this.listViewTarget).find('#export_list').attr('data-value', selectedList.value);
    }else{
      $(this.listViewTarget).find('#import_list').attr('data-value', selectedList.value);
      if(this.hasImportActionTarget){
        var inactiveAction = this.importActionTarget.options[this.importActionTarget.options.selectedIndex];
        $(this.listViewTarget).find('#import_action').attr('data-value', inactiveAction.value);
      }
    }

    if(this.hasRuleNameTextTarget){
      $(this.ruleNameTextTarget).html($(this.ruleNameTarget).find("option:selected").text())
    }
    $(this.systemTextTarget).html($(this.listTypeTarget).find("option:selected").text())
    if(this.hasImportActionTarget && this.hasImportActionTextTarget){
      $(this.importActionTextTarget).html($(this.importActionTarget).find("option:selected").text())
    }
  }

  cancelEditing(e) {
    if(this.isEditing == true){
      this.isEditing = false;
      this.listViewTarget.classList.remove('hidden-important');
      this.editSectionTarget.hidden = true;
    }else{
      this.element.remove();
    }
  }
}
