
const format=(format, ...params)=> {
    return format.replace(/{(\d+)}/g, (match, number) => {
        return typeof params[number] !== 'undefined' ? params[number] : match;
    });
};
const controlOfDisableElements = (isDisabled, ...elements) => {
    elements.forEach(element => (isDisabled ? element.setAttribute('disabled', isDisabled) : element.removeAttribute('disabled')))
}
const HIDE_ELEMENT_CLASS = 'hide-element'
const hideOrShowElements = (elementsToHide, elementsToShow = [], className = HIDE_ELEMENT_CLASS) => {
    const hide = Array.isArray(elementsToHide) ? elementsToHide : [elementsToHide]
    const show = Array.isArray(elementsToShow) ? elementsToShow : [elementsToShow]
    hide.forEach(element => (element.classList.add(className)))
    show.forEach(element => (element.classList.remove(className)))
}
const hideOrShowElementsByCondition = (isHide, ...elements) => {
    if (isHide) {
        hideOrShowElements(elements)
    }
    else {
        hideOrShowElements([], elements)
    }
}
const dispatchCustomEvent = (eventName, detail, element = document) => {
    element.dispatchEvent(new CustomEvent(eventName, { bubbles: true, detail }))
}

const schoolerFetch= (url, data = {}, isVerifyToken = true, responseType = 'JSON', method = 'GET') => {
    const headers = {
        'Content-Type': 'application/json',
        ...isVerifyToken && { 'X-CSRF-Token': $.rails.csrfToken() }
    }
    const updatedUrl = new URL(`${location.origin}/${url}`);
    if (method.toLocaleUpperCase() === 'GET') {
        updatedUrl.search = new URLSearchParams(data);
    }
    return fetch(updatedUrl, {
        method: method,
        headers: headers,
        body: method.toLocaleUpperCase() === 'GET' ? undefined : JSON.stringify(data)
    })
    .then(res => responseType.toLocaleUpperCase() == 'JSON' ? res.json() : res.text())
    .catch(e => console.error(e))
}

const isUrlValid = (url) => {
  try {
    const validUrl = new URL(url);
    return validUrl.protocol.startsWith('https:');
  } catch (err) {
    return false;
  }
}

const DISABLE_CLASS = 'disabled';

export {
    format,
    isUrlValid,
    DISABLE_CLASS,
    HIDE_ELEMENT_CLASS,
    controlOfDisableElements,
    hideOrShowElements,
    hideOrShowElementsByCondition,
    dispatchCustomEvent,
    schoolerFetch
}
