import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['selectCourses', 'selectStandaloneCourse', 'schoolCourses', 'limited'];

  connect() {
    this.fetchSchoolBundlesUrl = this.element.dataset.schoolCoursesUrl;
    if (this.element.dataset.initialSchool) {
      this.activeSchool = this.element.dataset.initialSchool;
      $(this.selectCoursesTarget).show().prop('disabled', false);
      $(this.selectStandaloneCourseTarget).hide().prop('disabled', true);
      if (this.element.dataset.limitedCoupon) {
        $(this.schoolCoursesTarget).prop('disabled', false);
        $('.select-student-courses', this.element).show();
      }
    }
  }

  changeSchool(ev) {
    this.activeSchool = ev.target.value;

    if (ev.target.value) {
      this.showSelectCoursesForSchool();
    } else {
      this.showSelectSingleCourse();
    }
  }

  toggleLimitCoursesCheckbox(e) {
    if ($(e.target).is(':checked')) {
      this.showMultiCourseSelect();
    } else {
      this.hideMultiCourseSelect();
    }
  }

  showMultiCourseSelect() {
    $(this.schoolCoursesTarget).prop('disabled', false);
    $('.select-student-courses', this.element).show();
    this.refreshActiveSchoolCourses();
  }

  hideMultiCourseSelect() {
    $(this.schoolCoursesTarget).prop('disabled', true);
    $('.select-student-courses', this.element).hide();
  }

  showSelectCoursesForSchool() {
    $(this.selectCoursesTarget).show().prop('disabled', false);
    $(this.selectStandaloneCourseTarget).hide().prop('disabled', true);
    if ($(this.limitedTarget).is(':checked')) {
      this.refreshActiveSchoolCourses();
    }
  }

  showSelectSingleCourse() {
    $(this.selectCoursesTarget).hide().prop('disabled', true);
    $(this.selectStandaloneCourseTarget).show().prop('disabled', false);
  }

  refreshActiveSchoolCourses() {
    const $el = $(this.schoolCoursesTarget);
    $.getJSON(this.fetchSchoolBundlesUrl, { school: this.activeSchool }, function(bundles) {
      $el.html(`
        ${bundles.map(bundle => (
          `<option data-draft="${bundle.hidden}" value="${bundle.id}">${bundle.name} </option>`
      )).join('')}
      `);
      $el.multiSelect('destroy');
      $el.multiSelect();
    });
  }
}
