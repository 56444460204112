export function fallbackCopyTextToClipboard(text) {
  let textArea;

  try {
    textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    if (!document.execCommand('copy')) {
      new Error('Oops, unable to copy');
    }

    return true;
  } catch (error) {
    console.warn('Oops, fallback solution unable to copy text');
    return false;
  } finally {
    if (textArea) document.body.removeChild(textArea);
  }
}

export function copyTextToClipboard(text = '') {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }

  return navigator.clipboard.writeText(text)
    .then(true, (error) => {
        console.warn('Oops, unabled to async copy.', error)
        return fallbackCopyTextToClipboard(text);
      }
    );
}
