import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
    static targets = ['trigger', 'display', 'code'];

    createCode(e) {
        e.preventDefault();
        const url = this.triggerTarget.dataset.url;
        $.post(url).then((res) => {
            this.codeTarget.textContent = res.code;
            this.displayTarget.style.visibility = 'visible';
        });
    }
}
