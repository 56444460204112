import { Controller } from 'stimulus';

// TODO: URL.revokeObjectURL()

export default class Image extends Controller {
    static targets = ['fileInput', 'previewImage', 'trashContainer'];

    imageChanged () {
      const [ image ] = this.fileInputTarget.files

      if ( image ) {
        const blobUrl = URL.createObjectURL( image )
        this.previewImageTarget.style.backgroundImage = `url(${blobUrl})`
        console.log( 'LIVE', 'Image loading...' )
      }
    }

    removeAvatarImage () {
      this.previewImageTarget.style.backgroundImage = ''
      this.trashContainerTarget.classList.add( 'nm-hidden' )

      //notify to server that current avatar is deleted
      const name = this.fileInputTarget.name.replace( 'avatar_image', 'avatar_image_destroy' )
      const hiddenField = document.createElement( 'input' )

      Object.assign( hiddenField, { type: 'hidden', value: '1', name } )
      document.forms[0].appendChild( hiddenField )
    }
}
