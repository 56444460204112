import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['school'];

  initialize() {
    $(this.schoolTarget).on('change', this.reloadBundles.bind(this));
  }

  reloadBundles() {
    const el = this.el;
    const select = $('.multiselect', this.el);
    select.html('');
    const url = $(this.schoolTarget).find(':selected').data('bundlesUrl');

    $.get(url, function(res) {
      $.each(res, function(idx, bundleData) {
        select.append(`<option value="${bundleData[1]}">${bundleData[0]}</option>`);
      });

      $('.multiselect', el).multiSelect("destroy").multiSelect({ keepOrder: true });
    });
  }
}
