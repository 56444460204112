import { Controller } from 'stimulus';
import { find } from 'lodash';
import { INVERT_USER_PROCESS_STATUS, CUSTOM_EVENT_NAMES } from '../../../services/meshulam_service';
import { schoolerFetch, dispatchCustomEvent, hideOrShowElementsByCondition } from '../../../common';

export default class extends Controller {
    static targets = ["dropdownButton", "dropdownMenu", "dropdownSelectedText", "newBusinessOption"]

    connect() {
      hideOrShowElementsByCondition(this.dropdownMenuTarget.children.length === 1, this.newBusinessOptionTarget)
    }
    //data fire custom event from ws Meshulam chanel
    meshulamDataReceived(e) {
        const { status, businessId, isBitAuthorized, isCreditCardAuthorized } = e.detail
        const { firstElementChild: textElement, lastElementChild: statusElement } = this.dropdownSelectedTextTarget

        const statusAsString = INVERT_USER_PROCESS_STATUS[status].toLocaleLowerCase()
        const text = window.I18n.meshulam_data.dropdown[statusAsString];
        const statusClass = `${statusAsString}-status`;
        Object.assign(textElement, { innerText: businessId })
        Object.assign(statusElement, { innerText: text, className: statusClass })
        Object.assign(this.dropdownMenuTarget.dataset, { currentBusinessId: businessId })
        isBitAuthorized !== undefined && Object.assign(this.dropdownSelectedTextTarget.dataset, { isBitAuthorized })

        isCreditCardAuthorized !== undefined && Object.assign(this.dropdownSelectedTextTarget.dataset, { isCreditCardAuthorized })

        let li = find(this.dropdownMenuTarget.childNodes, ({ nodeName, dataset }) => nodeName === 'LI' && dataset.value === businessId.toString())

        if (li) {
            Object.assign(li.firstElementChild.nextElementSibling, { innerText: text, className: statusClass })
            Object.assign(li.dataset, { status, linkedPayments: parseInt(li.dataset.linkedPayments) + 1 })
        } else {
            schoolerFetch(this.element.dataset.updatedDropdownUrl, { businessId }, true, 'text')
                .then(dropDownAsHtml => {
                    if(dropDownAsHtml) {
                        let tempNode = document.createElement('DIV')
                        tempNode.innerHTML = dropDownAsHtml
                        const newLi = tempNode.querySelector(`li[data-value="${businessId}"]`)
                        if (newLi) {
                            this.dropdownMenuTarget.insertBefore(newLi.cloneNode(true), this.dropdownMenuTarget.firstChild)
                        }
                        tempNode = null
                        hideOrShowElementsByCondition(this.dropdownMenuTarget.children.length === 1, this.newBusinessOptionTarget)
                    }
                })
        }
    }

    openDropdown(e) {
        e.stopPropagation()
        this.dropdownMenuTarget.classList.add('open');
    }
    closeDropdown(e) {
        if (!this.dropdownButtonTarget.contains(e.target)) {
            this.dropdownMenuTarget.classList.remove('open');
        }
    }

    businessIdChanged(e) {
        const li = e.target.closest('li')
        const { value: businessId, status, isBitAuthorized, isCreditCardAuthorized } = li.dataset
        const { firstElementChild: textElement, lastElementChild: statusElement } = this.dropdownSelectedTextTarget

        if (businessId !== this.dropdownMenuTarget.dataset.currentBusinessId) {
            if (businessId === '-1') {
                Object.assign(textElement, { textContent: li.textContent })
                Object.assign(statusElement, { textContent: '', className: '' })
            } else {
                Object.assign(textElement, { textContent: businessId })
                const statusAsString = INVERT_USER_PROCESS_STATUS[status].toLocaleLowerCase()
                Object.assign(statusElement, {
                    innerText: window.I18n.meshulam_data.dropdown[statusAsString],
                    className: `${statusAsString}-status`
                })
            }
            this.dropdownMenuTarget.dataset.currentBusinessId = businessId;
            this.dropdownSelectedTextTarget.dataset.isBitAuthorized = isBitAuthorized;
            this.dropdownSelectedTextTarget.dataset.isCreditCardAuthorized = isCreditCardAuthorized;
            this.dropdownMenuTarget.classList.toggle('open');
        }
    }

    updateRecord(e) {
        if (e.detail.businessId) {
            this.deleteRecord(e.detail.businessId)
        }
    }

    showDialog(e) {
        e.stopPropagation();
        const li = e.target.closest('li')
        const { value: businessId, linkedPayments } = li.dataset
        //checked if linked to this meshulaminfo more than one paymentinfo
        //if so show warning message
        if (parseInt(linkedPayments) > 1) {
            dispatchCustomEvent(CUSTOM_EVENT_NAMES.WARNING_DIALOG, { open: true, businessId })
        } else {
            this.deleteRecord(businessId)
        }
    }

    deleteRecord(businessId) {
        schoolerFetch(this.element.dataset.updateUrl, { businessId }, true, 'text', 'POST')
            .then(dropDownAsHtml => {
                this.dropdownMenuTarget.classList.remove('open');
                let tempNode = document.createElement('DIV')
                tempNode.innerHTML = dropDownAsHtml
                this.dropdownMenuTarget.innerHTML = tempNode.querySelector('ul').innerHTML
                tempNode = null
                //for delete previous option
                this.dropdownMenuTarget.dataset.currentBusinessId = ''
                //simulate click on "add new business id" option
                this.dropdownMenuTarget.lastElementChild.click();
                hideOrShowElementsByCondition(this.dropdownMenuTarget.children.length === 1, this.newBusinessOptionTarget)
            })
    }
}
