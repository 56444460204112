import { Controller } from "stimulus";
import { copyTextToClipboard } from '../../services/copy_text_to_clipboard'
import { hideOrShowElements, hideOrShowElementsByCondition, controlOfDisableElements} from '../../common';

export default class extends Controller {
  static targets = ['keyInput', 'secretInput', 'submitButton', 'cancelButton', 'editButton',
                    'secretInputNewSystem', 'submitButtonV2', 'cancelButtonV2', 'editButtonV2',
                    'apiSecretlabel', 'clientSection', 'instructionSection', 'listsConfirmationModal',
                    'modalInactiveBackdrop', 'warningModal', 'headerText', 'subHeaderText', 'radioText', 'copySecretIcon',
                    'confirmBtn', 'toggleApiCheckBox', 'secretKey', 'toggleSecretIcon', 'regenearteWarningModal'];

  connect() {
    controlOfDisableElements(this.isCurrentUserHasTokens, this.keyInputTarget, this.secretInputTarget)
    hideOrShowElementsByCondition(!this.isCurrentUserHasTokens, this.editButtonTarget)
    hideOrShowElementsByCondition(this.isCurrentUserHasTokens, this.submitButtonTarget, this.cancelButtonTarget)
    controlOfDisableElements(!this.isCurrentUserHasTokens, this.submitButtonTarget)

    controlOfDisableElements(this.isCurrentUserHasV2Tokens, this.secretInputNewSystemTarget)
    hideOrShowElementsByCondition(!this.isCurrentUserHasV2Tokens, this.editButtonV2Target)
    hideOrShowElementsByCondition(this.isCurrentUserHasV2Tokens, this.submitButtonV2Target, this.cancelButtonV2Target)
    controlOfDisableElements(!this.isCurrentUserHasV2Tokens, this.submitButtonV2Target)
  }

  get isCurrentUserHasTokens() {
    return this.keyInputTarget.value && this.secretInputTarget.value
  }

  editSettings() {
    hideOrShowElements([this.editButtonTarget], [this.submitButtonTarget, this.cancelButtonTarget])
    controlOfDisableElements(false, this.keyInputTarget, this.secretInputTarget)
  }

  cancelEditing() {
    if (this.isCurrentUserHasTokens) {
      controlOfDisableElements(true, this.keyInputTarget, this.secretInputTarget)
      hideOrShowElements([this.submitButtonTarget, this.cancelButtonTarget], [this.editButtonTarget])
    }
  }

  inputKeypress() {
    controlOfDisableElements(!this.isCurrentUserHasTokens, this.submitButtonTarget)
  }

  v1SettingsSaved(event){
    this.cancelEditing()
  }

  get isCurrentUserHasV2Tokens() {
    return this.secretInputNewSystemTarget.value
  }

  editSettingsV2() {
    hideOrShowElements([this.editButtonV2Target], [this.submitButtonV2Target, this.cancelButtonV2Target])
    controlOfDisableElements(false, this.secretInputNewSystemTarget)
  }

  cancelEditingV2() {
    if (this.isCurrentUserHasV2Tokens) {
      controlOfDisableElements(true, this.secretInputNewSystemTarget)
      hideOrShowElements([this.submitButtonV2Target, this.cancelButtonV2Target], [this.editButtonV2Target])
    }
  }

  inputKeypressV2() {
    controlOfDisableElements(!this.isCurrentUserHasV2Tokens, this.submitButtonV2Target)
  }

  v2SettingsSaved(event){
    this.cancelEditingV2()
  }

  showConfirmationDialog(ev) {
    if(ev.target.dataset.firstTime === 'true'){
      this.confirmToggle(ev);
    }else{
      if(ev.target.checked){
        this.headerTextTarget.textContent = 'להפעיל את החיבור?';
        this.subHeaderTextTarget.textContent = 'המידע יתחיל לעבור ברגע שהחיבור יופעל';
        this.confirmBtnTarget.textContent = "כן, להפעיל";
        this.showModal(ev, true);
      }else {
        this.headerTextTarget.textContent = 'לכבות את החיבור?';
        this.subHeaderTextTarget.textContent = 'המידע יפסיק לעבור עד שהחיבור יופעל מחדש';
        this.confirmBtnTarget.textContent = "כן, לכבות";
        this.showModal(ev, false);
      }
    }
  }

  preventClick(ev) {
    ev.preventDefault();
  }

  revertCheck() {
    if (this.toggleApiCheckBoxTarget.checked === true) {
      this.toggleApiCheckBoxTarget.checked = false;
    } else {
      this.toggleApiCheckBoxTarget.checked = true;
    }
  }

  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.revertCheck();
      this.hideModal(e);
  }

  confirmToggle(e) {
    let enable = false;
    if(this.toggleApiCheckBoxTarget.checked){
      enable = true;
    }
    const self = this;
    $.ajax({
      type: "POST",
      url: '/rav_meser_settings/toggle_api_access',
      data: JSON.stringify({ api_enabled: enable }),
      contentType: "application/json",
      dataType: "json",
      success: function(data) {
        if(data['api_enabled'] === true){
          self.clientSectionTarget.style.display = 'block';
          self.instructionSectionTarget.style.display = 'none';
          self.secretKeyTarget.value = data['api_secret'];
          self.radioTextTarget.textContent = 'פעיל';
          self.radioTextTarget.classList.remove('radio-text-red');
        }else{
          self.clientSectionTarget.style.display = 'none';
          self.instructionSectionTarget.style.display = 'block';
          self.radioTextTarget.textContent = 'לא פעיל';
          self.radioTextTarget.classList.add('radio-text-red');
        }
      },
      error: function(data, textStatus, err) {
        self.revertCheck();
      }
    })
  }

  showConfirmRegeneration(ev) {
    $(this.regenearteWarningModalTarget).addClass('show').show();
    $(this.modalInactiveBackdropTarget).show();
  }

  regenerateSecret(e) {
    const self = this;
    $.ajax({
      type: "PUT",
      url: '/rav_meser_settings/regenerate_api_secret',
      dataType: "json",
      success: function(data) {
        if(data['api_secret'] !== undefined){
          self.secretKeyTarget.value = data['api_secret'];
        }
      },
      error: function(data, textStatus, err) { },
      complete: function() {
        $(self.regenearteWarningModalTarget).add(self.modalInactiveBackdropTarget).hide();
      }
    })
  }

  cancelRegeneration(){
    $(this.regenearteWarningModalTarget).add(this.modalInactiveBackdropTarget).hide();
  }

  cancel(e) {
    this.revertCheck();
    this.hideModal(e);
  }

  hideModal(e) {
    $(this.warningModalTarget).add(this.modalInactiveBackdropTarget).hide();
  }

  showModal(e, checked) {
      $(this.warningModalTarget).addClass('show').show();
      $(this.modalInactiveBackdropTarget).show();
  }

  copyToClipboard(event) {
    event.preventDefault();

    let textBox = event.target.nextElementSibling;

    let copiedSuccessfully = copyTextToClipboard(textBox.value);

    if (copiedSuccessfully) {
      $('.notifyjs-wrapper').trigger('notify-hide');
      $(textBox).notify('הועתק בהצלחה' || '', 'success');
    }
  }

  toggleSecret(ev) {
    const secretInput = this.secretKeyTarget;

    if (secretInput.type === "password") {
      secretInput.type = "text";
      this.toggleSecretIconTarget.className = "hide-secret";
      this.copySecretIconTarget.style.display = 'block';
    } else {
      secretInput.type = "password";
      this.toggleSecretIconTarget.className = "show-secret";
      this.copySecretIconTarget.style.display = 'none';
    }
  }
}
