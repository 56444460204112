import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    $.notify.addStyle('tooltip-errors', {
      html: "<div><span data-notify-text style='font-size: 16px'></span></div>",
      classes: {
        base: {
          "white-space": "nowrap",
          "background-color": "#F2DEDE",
          "padding": "5px",
          "color": '#B94A48',
          'font-weight': 'bold',
          'border-radius': '6px',
          'border-color': '#EED3D7',
          'border-style': 'solid',
          'border-width': "1px"
        }
      }
    });
  }

  showError(ev) {
    ev.preventDefault();
    const displayState = $(ev.currentTarget).closest("div").find('div.notifyjs-container').css('display')
    if (displayState === 'block') return;

    const errors = ev.currentTarget.dataset.errors;
    if (!errors) return;

    $(ev.currentTarget).notify(errors, {style: 'tooltip-errors', clickToHide: true, autoHide: false, elementPosition: 'bottom right' });
  }
}
