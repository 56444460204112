import { Controller } from "stimulus";
import $ from 'jquery';
const SLUG_VALID_CHARACTERS_REGEX = /[^a-zA-Z0-9\-\/_]/gi;

export default class extends Controller {
  static targets = ['affectedByCurrentPolicy', 'modal', 'modalBackdrop', 'slugInput'];

  initialize() {
    const policyName = $('div[data-controller="payment-info-and-policy"]').attr('data-type');
    this.changeSignupPolicyByName(policyName);

    this.publishedOnce = this.data.get('publishedOnce');
    this.$saveBtns = $('.distance-md input[class="nm-btn"]');
    //in case when course is published
    if (this.hasSlugInputTarget) {
      this.removeSpecialCharactersFromSlug();
    }
  }

  changeSignupPolicy(ev) {
    const currentPolicy = this.currentPolicy = ev.currentTarget.dataset.name;
    this.changeSignupPolicyByName(currentPolicy)
  }

  changeSignupPolicyByName(policyName) {
    $.each(this.affectedByCurrentPolicyTargets, (index, el) => {
      const hideOn = new Set(el.dataset.hideOn.split(' '));
      if (hideOn.has(policyName)) {
        $(el).hide();
      } else {
        $(el).show();
      }
      this.setInputsDisabled(el, policyName === el.dataset.hideOn);
    });
  }

  setInputsDisabled(el, val) {
    const inputs = el.querySelectorAll('input, select, textarea');
    for (let i=0; i < inputs.length; i++) {
      if (inputs[i].className == 'destroy' || inputs[i].className == 'import-state') { continue; }
      inputs[i].disabled = val;
    }
  }

  submitForm(e) {
    const neverPublished = this.publishedOnce === 'false';
    const forPublish = $('#bundle_hidden').val() === 'false';
    if (neverPublished && forPublish) {
      const modal = $(this.modalTarget);
      const modalBackdrop = $(this.modalBackdropTarget);
      modalBackdrop.show();
      modal.addClass('show');
      modal.show();
      e.preventDefault();
    }

    // Bad workaround
    this.removeFieldsBasedOnUnchecked();
  }

  removeFieldsBasedOnUnchecked() {
    const termsUrlDisabled     = !$('#terms-url-enabled').prop('checked');
    const afterJoinUrlDisabled = !$('#afterjoin-url-enabled').prop('checked');

    if (termsUrlDisabled) {
      $('.terms-hiddenfield').remove();
    }

    if (afterJoinUrlDisabled) {
      $('.afterjoin-url-textfield').remove();
    }
  }

  sendFormApproved(e) {
    $('form[data-target="course-signup-live-preview.form"]').submit();
  }

  hideModal(e) {
    $(this.modalTarget).hide();
    $(this.modalBackdropTarget).hide();
  }

  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.hideModal(e);
  }
  onInputValid(e) {
    const $input = $(e.target);
    const pattern = $input.get(0).dataset.pattern;
    if ($input.val() && pattern && !new RegExp(pattern, 'i').test($input.val())) {
      $input.addClass('error');
      const $notes = $input.parent().next()
      if ($notes.hasClass('input-note')) {
        $input.parent().addClass('error')
      }
      this.$saveBtns.attr('disabled', true);
    } else {
      $input.removeClass('error');
      const $notes = $input.parent().next()
      if ($notes.hasClass('input-note')) {
        $input.parent().removeClass('error')
      }
      this.$saveBtns.removeAttr('disabled');
    }
  }
  removeSpecialCharactersFromSlug() {
    let newSlug = this.slugInputTarget.value.replace(/\s/g, '-');
    this.slugInputTarget.value = newSlug.replace(SLUG_VALID_CHARACTERS_REGEX, '') || Date.now();
  }
}
