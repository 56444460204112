import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = ['customSelect'];

  connect() {
    $(this.customSelectTarget).select2({
      theme: "bootstrap",
      width: 'resolve',
      placeholder: 'בחרו קורס להוספה',
      dir: 'rtl',
      minimumResultsForSearch: Infinity,
      templateResult: this.formatState,
    })
  }

  formatState(data) {
    if (!data.id) {
      return data.text;
    }
    var isDraft = data.element.dataset.draft;
    var option = $(`<span>${data.text}</span>`);
    if(isDraft == 'true'){
      option = $(`<span>${data.text}</span> <span style="float:left;"  class="icon-draft-label icon-left-align"><span class="label-text">טיוטה</span><span class="icon-draft"></span></span>`);
    }
    return option;
  }
}
