import ShowHideController from './show_hide_controller';

export default class extends ShowHideController {
  initialize() {
    super.initialize();
  }

  show() {
    super.show();
    this.componentTarget.querySelectorAll('input').forEach(el => { el.disabled = false; });
  }

  hide() {
    super.hide();
  }
}
