import { Controller } from 'stimulus';
import $ from 'jquery';
import { debounce, isEmpty } from 'lodash';

import { format, isUrlValid, DISABLE_CLASS, controlOfDisableElements } from '../../../common';
import termService from '../../../services/term_service';

const ANCHOR_ELEMENT_FORMAT = '<a href="{0}" data-blob-id={1} target="_blank">{2}</a>';

export default class ModalController extends Controller {
  static targets = ['modalOptionLink', 'modalOptionLinkBackdrop', 'linkFeedbackError', 'termLinkCheckbox',
  'inputLink', 'inputPdf', 'saveButton', 'linkAction', 'clearAction', 'termsHiddenInput', 'termsWrapper',
  'directUploadProgressContainer', 'labelContainer', 'deletePdfBtn']

  initialize() {
    this.$modalOptionLinkTarget = $(this.modalOptionLinkTarget)
    this.$modalOptionLinkBackdropTarget = $(this.modalOptionLinkBackdropTarget)
    this.$linkFeedbackErrorTarget = $(this.linkFeedbackErrorTarget)
    this.saveButtonTarget.classList.add(DISABLE_CLASS)
    this.updateModal()
    this.preview= null

    this.inputLinkKeyUpDebounce = debounce(this.inputLinkKeyUp.bind(this), 1000);
    this.inputLinkTarget.addEventListener('keyup', this.inputLinkKeyUpDebounce);
    this.inputPdfTarget.addEventListener('fileUploaded', this.fileUploaded.bind(this))
  }

  disconnect() {
    this.inputLinkTarget.removeEventListener('keyup', this.inputLinkKeyUpDebounce);
    this.inputPdfTarget.removeEventListener('fileUploaded', this.fileUploaded.bind(this))
  }

  showModal() {
    if (termService.CurrentSelection && termService.CurrentSelection.text) {
      this.$modalOptionLinkTarget.addClass('show')
        .add(this.$modalOptionLinkBackdropTarget)
        .show();

      this.updateModal()
      this.saveButtonTarget.classList.add(DISABLE_CLASS)
    }
  }
  isModalDisplay() {
    return !this.$modalOptionLinkTarget.is(':hidden')
  }
  hideModal(e) {
    this.$modalOptionLinkTarget.removeClass('show')
                               .add(this.$modalOptionLinkBackdropTarget)
                               .hide();
    this.inputLinkTarget.value = ''
    this.setErrorVisibilityState(true)
    controlOfDisableElements(true, this.linkActionTarget)
    termService.CurrentSelection = null
  }
  saveLink(e) {
    const selectedText = termService.CurrentSelection.text
    if (selectedText && !e.target.classList.contains(DISABLE_CLASS)) {
      let url ='#'
      let blobId = -1

      if(!this.inputLinkTarget.disabled){
        url = this.inputLinkTarget.value
      } else if(this.preview){
        url = ''
        blobId = this.preview.blobId
      }
     if(url !== '#') {
        const anchor = format(ANCHOR_ELEMENT_FORMAT, url, blobId, selectedText)
        this.termsWrapperTarget.innerHTML = this.termsWrapperTarget.innerHTML.replace(selectedText, anchor)
        this.termsHiddenInputTarget.value = this.termsWrapperTarget.innerHTML.trim()
     } else{
       console.error('url not chosen')
     }
      this.hideModal();
    }
  }
  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.hideModal(e);
  }
  setErrorVisibilityState(isHide) {
    this.$linkFeedbackErrorTarget.css('visibility', isHide ? 'hidden' : 'visible');
  }
  inputLinkFocus(e){
    controlOfDisableElements(false, this.inputLinkTarget)
  }
  inputLinkKeyPress(e) {
    if (e.which === 13/*Enter*/) {
      e.preventDefault();
    }
  }
  inputLinkKeyUp(e) {
    this.inputLinkUpdated(e.target.value)
  }

  inputLinkPaste(e) {
    this.inputLinkUpdated(e.clipboardData.getData('text/plain'))
  }
  deletePdfBtnClicked(e){
    e.preventDefault()
    this.updateModal()
    termService.deleteHiddenWithBlobId(this.preview.blobId)
    controlOfDisableElements(false, this.inputLinkTarget)
    this.saveButtonTarget.classList.add(DISABLE_CLASS)
    this.preview= null
  }

  fileUploaded(e){
    this.preview = e.detail
    controlOfDisableElements(true, this.inputLinkTarget)
  }

  updateModal() {
    this.inputLinkTarget.value = ''
    this.directUploadProgressContainerTarget.innerText = this.directUploadProgressContainerTarget.dataset.placeholder
    this.inputPdfTarget.className = 'pdf-file-input';
    this.labelContainerTarget.dataset.label = this.labelContainerTarget.dataset.upload
    this.setErrorVisibilityState(true)
    controlOfDisableElements(false, this.inputPdfTarget, this.inputLinkTarget)
  }

  inputLinkUpdated(value) {
    const isValid = isUrlValid(value);
    if (!isValid) {
      this.saveButtonTarget.classList.add(DISABLE_CLASS)
    }
    else {
      this.saveButtonTarget.classList.remove(DISABLE_CLASS)
    }

    this.setErrorVisibilityState(!value || isValid)
    controlOfDisableElements(!isEmpty(value), this.inputPdfTarget)
  }
}
