import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = ['modalConfirmSaveUser', 'modalConfirmSaveUserBackdrop', 'createUserForm', 'isSchoolDraft', 'headerText', 'deleteListMessage'];

  initialize() {
    this.numberOfDraftCourses = 0;
    this.draftSchool = false;
  }

  showConfirmationDialog(e) {
    this.numberOfDraftCourses = $(this.createUserFormTarget).find('option:selected').filter('[data-draft="true"]:not([selected="selected"])').length
    if(this.hasIsSchoolDraftTarget && this.isSchoolDraftTarget.value == 'true'){
      this.draftSchool = true;
    }
    if(this.numberOfDraftCourses > 0 || this.draftSchool){
      if(this.draftSchool){
        this.headerTextTarget.textContent = "הבית ספר במצב טיוטה. האם לצרף את התלמיד/ה בכל זאת?";
        this.deleteListMessageTarget.textContent = "פרטי הגישה שיישלחו לא יעבדו כל עוד הבית ספר במצב טיוטה."
      }else{
        this.headerTextTarget.textContent = "הקורס במצב טיוטה.  האם לצרף את התלמיד/ה בכל זאת?";
        this.deleteListMessageTarget.textContent = "פרטי הגישה שיישלחו לא יעבדו כל עוד הקורס במצב טיוטה"
      }
      $(this.modalConfirmSaveUserTarget).addClass('show').show();
      $(this.modalConfirmSaveUserBackdropTarget).show();
      e.preventDefault();
    }else{
      $(this.createUserFormTarget).submit();
    }
  }

  hideModal(e) {
    $(this.modalConfirmSaveUserTarget).add(this.modalConfirmSaveUserBackdropTarget).hide();
  }

  outsideClick(e) {
    if (e.target === e.currentTarget)
      this.hideModal(e);
  }

  saveUser(e) {
    $(this.createUserFormTarget).submit();
    this.hideModal(e);
  }
}
