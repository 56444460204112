class TermService {
    getSelectedAnchors(selection, container) {
        const anchors = selection.toString() ? Array.from(container.childNodes).reduce((acc, node) => {
            return node.nodeName === 'A' && selection.containsNode(node, true) ? acc.concat(node) : acc
        }, []) : []
        return anchors
    }
    get CurrentSelection() {
        return this.currentSelection;
    }

    set CurrentSelection(selection) {
        if (selection) {
            const { anchorNode, focusNode, anchorOffset, focusOffset } = selection
            this.currentSelection = {
                startNode: anchorNode,
                endNode: focusNode,
                startNumber: anchorOffset,
                endNumber: focusOffset,
                text: selection.toString()
            };
        } else {
            this.currentSelection = selection
        }
    }
    getSelectedText() {
        let text = '';
        if (window.getSelection) {
            text = window.getSelection();
        } else {
            //should only be used for IE < 9.
            //we doesn't support
            //if (document.selection && document.selection.type !== 'Control')
            //text = document.selection.createRange();
            console.error('doesn\'t support browser for selection')
        }
        return text;
    }
    markAsDeleteAttachment(fileInputName, blobId) {
        const name = fileInputName.replace('term_pdfs', 'blob_ids_for_destroy')
        const hiddenField = document.createElement('input')
        Object.assign(hiddenField, { type: 'hidden', value: blobId, name })
        document.forms[0].appendChild(hiddenField)
    }
    getAttachmentUrl(termsAttachmentsUrl, blobId) {
        return fetch(`${termsAttachmentsUrl}?blob_id=${blobId}`, {
            method: 'GET',
            headers: new Headers({ 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }),
        })
            .then(response => response.json())
            .then(res => {
                if(res.status === 'success') {
                    return res;
                } else {
                    console.error(res.error)
                    return null;
                }
            })
            .catch(e => console.error(`Can not get attachment url the reason is:${e}`))
    }
    deleteHiddenWithBlobId(blobId) {
        const blobHidden = document.querySelector(`input[type='hidden'][data-blob-id='${blobId}']`)
        return blobHidden && blobHidden.parentNode.removeChild(blobHidden)
    }
}
export default new TermService()