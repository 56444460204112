import ConditionalComponentController from './conditional_component_controller';

export default class extends ConditionalComponentController {
  static targets = ['useDomainCheckBox', 'domainInput', 'subdomainInput', 'mainDomainInput', 'useDomainInputNote', 'checkDomainBtn', 'certStatusDiv', 'domainChangeMsg', 'domainValiditySection', 'newDomainName'];

  initialize() {
    const mainDomain = this.mainDomainInputTarget.value;
    if (mainDomain != '') {
      this.subdomainInputTarget.value = mainDomain.substr(0, mainDomain.indexOf('.'));
      this.domainInputTarget.value = mainDomain.substr(mainDomain.indexOf('.') + 1);
      this.useDomainCheckBoxTarget.checked = true;
    }
    super.initialize();
  }

  inputChanged(ev) {
    if(ev === undefined){ return }

    var subdomain = this.subdomainInputTarget.value;
    if (subdomain != '') {
      subdomain += '.';
    }

    this.mainDomainInputTarget.value = subdomain + this.domainInputTarget.value;
    this.setDomainCheckUrl();
    showBundleUrlFromSlug();
  }

  show() {
    super.show();
    this.inputChanged();
  }

  hide() {
    super.hide();
    this.mainDomainInputTarget.value = '';
    showBundleUrlFromSlug();
  }

  setDomainCheckUrl() {
    const url = new URL(this.checkDomainBtnTarget.href);
    url.searchParams.set('domain_name', this.mainDomainInputTarget.value);
    this.checkDomainBtnTarget.href = url;
    this.checkDomainBtnTarget.style.display = 'block';
    if(this.hasCertStatusDivTarget){
      this.certStatusDivTarget.style.display = 'none';
      this.domainChangeMsgTarget.style.display = 'block';
    }
    if(this.hasNewDomainNameTarget){
      this.newDomainNameTarget.innerText = 'לאחר השמירה הדומיין ישתנה ל ' + this.mainDomainInputTarget.value;
    }
    for (var i = 0; i < this.domainValiditySectionTarget.children.length; i = i + 1) {
      this.domainValiditySectionTarget.children[i].style.display = 'none';
    }
  }
}
