import { Controller } from 'stimulus';
import $ from 'jquery';

export default class ModalWarningController extends Controller {
    static targets = ['modalOption', 'saveButton']

    initialize() {
        this.$modalOption = $(this.modalOptionTarget)
        this.$modalOptionBackdrop = $('.meshulam-backdrop')
    }

    showModal() {
        this.$modalOption.addClass('show')
            .add(this.$modalOptionBackdrop)
            .show();

    }
    isModalDisplay() {
        return !this.$modalOption.is(':hidden')
    }
    hideModal(e) {
        this.$modalOption.removeClass('show')
            .add(this.$modalOptionBackdrop)
            .hide();
    }

    outsideClick(e) {
        if (e.target === e.currentTarget)
            this.hideModal(e);
    }
}
