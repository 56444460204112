import { Controller } from "stimulus";
import { safeHtml } from 'common-tags';
import $ from 'jquery';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['trigger', 'list', 'title'];

  initialize() {
    this.renameCode = debounce(this.renameCode).bind(this);
    this.toggleTable();
  }

  connect() {
    $(this.listTarget).on('click', '.icon-remove', this.destroyCode.bind(this));
    $(this.listTarget).on('input', '.sharecode-name', this.renameCode);
  }

  renameCode(e) {
    const $li = $(e.target).closest('.units-list-item');
    const url = this.triggerTarget.dataset.url;
    const code = $li.find('.cell.sharecode').text();
    const path = url.trim() + '/' + code.trim();
    const name = e.target.textContent.trim().replace('\n', ' ');
    $.post(path, { name: name });
  }

  createCode(e) {
    e.preventDefault();
    const url = this.triggerTarget.dataset.url;
    $.post(url).then((res) => {
      $(this.listTarget).append(safeHtml`
        <li class="units-list-item">
            <div class="cell sharecode">${res.code}</div>
            <div class="cell">0</div>
            <div class="cell sharecode-name" contenteditable="true">${res.name}</div>
            <div class="cell nm-tooltiped">
              <a
                data-confirm="בטוח שברצונך למחוק קוד שיתוף זה? מחיקה תגרום להסרת הקורס מכל בתי הספר החיצוניים בהם הוא מופיע"
                class="icon icon-remove"
                rel="nofollow"
                data-method="delete"
                href="${res.destroy_path}">
              </a>
              <div class="nm-tooltip">
                  <div class="tooltip-inner">מחיקה</div>
              </div>
            </div>
        </li>
      `);
      this.toggleTable();
    });
  }

  destroyCode(e) {
    const url = e.target.getAttribute('href');
    e.preventDefault();
    e.stopPropagation();
    if (!confirm(e.target.dataset.confirm)) {
      return;
    }

    $.ajax({
      method: 'DELETE',
      url: url,
    }).then(() => {
      $(e.target).closest('.units-list-item').remove();
      this.toggleTable();
    });
  }

  toggleTable() {
    const show = $(this.listTarget).find('li.units-list-item').length != 0;
    $(this.listTarget).toggle(show);
    $(this.titleTarget).toggle(show);
  }
}
